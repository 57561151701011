import Login from '../../views/Auth/Login.vue'
import Main from '../../views/Company/Main.vue'

const routes = [
    {
      path: '',
      redirect : to => {
          return { name : 'login' }
      }
    //   name: 'login',
    //   component: Login
    },
    {
        path: '/login',
        name: 'login',
        component: Login
      },
    {
      path: '/register',
      name: 'register',
      // route level code-splitting
      // this generates a separate chunk (register.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "chunck" */ '../../views/Auth/Register.vue')
    },
    {
        path: '/forget-password',
        name: 'forget',
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chunck" */ '../../views/Auth/forget-pass/Forget.vue')
    },
    {
        path: '/invite/',
        name: 'invite',
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chunck" */ '../../views/Invite/AccountInvite.vue')
    },
    {
        path: '/confirmation/',
        name: 'confirmation',
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chunck" */ '../../views/Invite/ConfirmAccount.vue')
    },
    {
        path: '/unavailable/',
        name: 'notfound',
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chunck" */ '../../views/NotFound.vue')
    },
    {
        path: '/invite-confirmation/',
        name: 'inviteconfirm',
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chunck" */ '../../views/Invite/ConfirmInvite.vue')
    },
    {
        path: '/reset-password/',
        name: 'reset',
        // route level code-splitting
        // this generates a separate chunk (register.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "chunck" */ '../../views/Auth/ResetPassword.vue')
    },
    {
        path: '/Company',
        // name: 'app',
        meta: { 
            requireAuth: true,
            title: 'Dashboard | Synkio', 
        },
        component: Main,
        children: [
            {
                path: '',
                name: 'dashboard',
                meta: { 
                    title: 'Dashboard | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/Dashboard.vue')
            },
            {
                path: 'app-connection',
                name: 'appconnect',
                meta: { 
                    title: 'App Connection | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/AppConnection.vue')
            },
            {
                path: 'connections/:id',
                name: 'connections',
                meta: { 
                    title: 'App Connection | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/SingleAppConnection.vue')
            },
            {
                path: 'logs/:uuid',
                name: 'connectionlogs',
                meta: { 
                    title: 'App Connection | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/AppWSLog.vue')
            },
            {
                path: 'appsettings',
                name: 'appsettings',
                meta: { 
                    title: 'Settings | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/AppSetting.vue')
            },
            {
                path: '404/unavailable',
                name: 'usernotfound',
                meta: { 
                    title: 'Unavailable | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/NotFound.vue')
            },
            {
                path: 'billing',
                name: 'billings',
                meta: { 
                    title: 'Settings | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/Billing.vue')
            },
            {
                path: 'add-apps',
                name: 'addapps',
                meta: { 
                    title: 'WebShop Apps | Synkio', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/AddApps.vue')
            },
            {
                path: 'logs',
                name: 'applogs',
                meta: { 
                    title: 'Apps logs | Synkio',
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/AppLog.vue')
            },
            {
                path: 'faq',
                name: 'faq',
                meta: { 
                    title: 'FAQ', 
                },
                component: () => import(/* webpackChunkName: "chunck" */ '../../views/Company/FAQ.vue')
            }

        ]
    },
    {
        path: '*',
        redirect : to => {
            return { name : 'usernotfound' }
        }
    },
]


export default routes
