import HTTPEntry from './../../../../services/vue-axios/entry'
import endpoints from './../../../../services/endpoint'
import TYPES from './../types'

export default {
    async refreshUserToken ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTPEntry.post(endpoints.REFRESH_USER_TOKEN, {
                refresh : payload
            })
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_TOKEN_AFTER_REFRESH, res.data)
                    resolve(res)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    async loginUser ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTPEntry.post(endpoints.AUTH_USER_LOGIN, payload)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_TOKEN, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async registerUser ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTPEntry.post(endpoints.AUTH_USER_REGISTER, payload)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async forgetPassword ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTPEntry.post(endpoints.FORGET_USER_PASSWORD, payload)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async resetPassword ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTPEntry.post(endpoints.RESET_USER_PASSWORD, payload)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async processInvite ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTPEntry.post(endpoints.INVITE_SET_PASSWORD, payload)
            .then(res => {
                if (res.data.success === true) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
}