import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import services from './services'
import './filters'
import './veevalidate'
import { DatePicker, Dialog, MessageBox, Button, Tooltip, Drawer, Loading, Select, Option } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import 'tailwindcss/dist/tailwind.css'
import Notifications from 'vt-notifications'
import infiniteScroll from 'vue-infinite-scroll'
import VWave from 'v-wave'
import VueCroppie from 'vue-croppie'
import i18n from '@/plugins/i18n'
import VueApexCharts from 'vue-apexcharts'
import 'croppie/croppie.css'


Vue.config.productionTip = false
locale.use(lang)
Vue.component('apexchart', VueApexCharts)

Vue.use(Loading.directive)
Vue.use(infiniteScroll)
Vue.use(services)
Vue.use(Notifications)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(VWave)
Vue.use(VueCroppie)
Vue.prototype.$confirm = MessageBox.confirm

window.Bus = new Vue()

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

// const app = new Vue({
//     i18n,
//     router,
//     store,
//     render: h => h(App)
// }).$mount('#app')

// if (window.Cypress) {
//     window.__app__ = app
// }
