import { saveLocale } from './../../../../services/storage-window'

export default {
    OPEN_INVITE_MODAL (state, _) {
        state.model_invite = !state.model_invite
    },
    OPEN_PHOTO_MODAL (state, _) {
        state.model_photo = !state.model_photo
    },
    OPEN_MODAL_UTIL (state, _) {
        state.model_util = !state.model_util
    },
    OPEN_MODAL_FAQ (state, _) {
        state.modal_inhouse = !state.modal_inhouse
    },
    OPEN_NOTIFICATION_DRAWER (state, _) {
        state.notificationDrawer = !state.notificationDrawer
    },
    CHANGE_LOCALE (state, payload) {
        state.setLocale = payload
        saveLocale(payload)
    }
}

// function storeUSER (data) {
//     window.localStorage.setItem('amdjango', data.access)
//     window.localStorage.setItem('amre', data.refresh)
//     if (data.user.is_accountant) {
//         window.localStorage.setItem('util', data.user.is_accountant)
//     }
//     if (data.user.is_in_house_user) {
//         window.localStorage.setItem('reghouse', data.user.is_in_house_user)
//     }
// }

// function setUserCompany (data, entity) {
//     if (entity === 'Firm') {
//         window.sessionStorage.setItem('xfirm', JSON.stringify(data))
//     } else {
//         window.sessionStorage.setItem('C', data)
//     }
// }

// // function setLocale (payload) {
// //     window.localStorage.setItem('xlocale', payload)
// // }

// function clearToken () {
//     window.localStorage.removeItem('amdjango')
//     window.localStorage.removeItem('amre')
//     window.localStorage.removeItem('util')
//     window.localStorage.removeItem('reghouse')
//     window.sessionStorage.removeItem('xmdjango')
//     window.sessionStorage.removeItem('xfirm')
//     window.localStorage.removeItem('randomc')
// }