import moment from 'moment'
import { setUserCompany } from './../../../../services/storage-window'

export default {
    UPDATE_USER_ROLE (state, payload) {
        state.userRole = payload
    },
    UPDATE_AVAILABLE_APPS (state, payload) {
        state.availableApps = JSON.parse(JSON.stringify(payload))
        state.copyAvailableApps = JSON.parse(JSON.stringify(payload))
    },
    GET_ALL_APPS (state, _) {
        state.availableApps.data = [...state.copyAvailableApps.data]
    },
    FILTER_APPS (state, payload) {
        const copyData = [...state.copyAvailableApps.data]
        const filterApp = copyData.filter(item => 
            item.app_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)    
        )
        state.availableApps.data = filterApp
    },
    UPDATE_CONNECTION_VALUES (state, payload) {
        state.connectionObject = payload
    },
    UPDATE_FAQ_CATEGORY (state, payload) {
        state.faqCategory = payload
        state.copyFaqCategory = payload
    },
    UPDATE_FAQ_DATA (state, payload) {
        state.faqData = payload
        state.copyFaqData = payload
    },
    ADD_CONNECTIONS (state, payload) {
        state.appConnection = payload
    },
    COMPLETE_PROCESSING (state, _) {
        state.completeAppSubmission = true
    },
    PROGRESS_MODAL_CLOSE (state, _) {
        state.completeAppSubmission = false
    },
    COMPLETE_SINGLE_CONNECTION (state, payload) {
        const singleConnection = state.appConnection.findIndex(item => parseInt(item.unique) === parseInt(payload.id))
        const copyState = [...state.appConnection]
        copyState[singleConnection] = {...copyState[singleConnection], complete : true }
        state.appConnection = copyState
    },
    FAIL_SINGLE_CONNECTION (state, payload) {
        const singleConnection = state.appConnection.findIndex(item => parseInt(item.unique) === parseInt(payload.id))
        const copyState = [...state.appConnection]
        copyState[singleConnection] = {...copyState[singleConnection], failed : true }
        state.appConnection = copyState
    },
    RESET_CONNECTION_INDICATOR (state, _) {
        const copyState = [...state.appConnection]
        copyState.forEach(element => {
            element.failed = false  
            element.complete = false  
        })
        state.appConnection = copyState
    },
    UPDATE_USER_CONNECTION (state, payload) {
        state.userConnections = payload
    },
    UPDATE_SINGLE_CONNECTION (state, payload) {
        // console.log('payload:', payload)
        const apiResponse = payload
        if (apiResponse.definition && !apiResponse.definition.actions) {
            apiResponse.definition = JSON.parse(apiResponse.definition)
        }
        state.singleConnectionObject = apiResponse
    },
    UPDATE_USER_SUBSCRIPTION (state, payload) {
        state.userSubscription = payload
    },
    UPDATE_APP_LOGS (state, payload) {
        state.AppLogs = JSON.parse(JSON.stringify(payload))
        state.copyAppLog = JSON.parse(JSON.stringify(payload))
    },
    UPDATE_APP_FILTER_LOGS (state, payload) {
        state.AppLogs = JSON.parse(JSON.stringify(payload))
    },
    UPDATE_CONNECTION_MADE (state, payload) {
        state.connectionMade.push(payload)
    },









    REMOVE_USER_DATA (state, payload) {
        state.userFirmID = payload
    },
    FILTER_CATEGORY (state, payload) {
        // let filterFaq = []
        // const filterCategory = state.copyFaqCategory.filter(item => 
        //     item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
        // )
        const filterFaq = state.copyFaqData.filter(item => 
            item.question.toLowerCase().includes(`${payload.value.toLowerCase()}`)
        )

        if (filterFaq.length < 1) {
            const filterCategory = state.copyFaqCategory.filter(item => 
                item.category_name.toLowerCase().includes(`${payload.value.toLowerCase()}`)
            )
            // console.log('maian:', filterCategory.length)
            if (filterCategory.length > 0) {
                state.faqCategory = filterCategory
                const filterq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
                // console.log('fil;', filterq)
                state.faqData = filterq
                // console.log('state data:', state.faqData)
            } else {
                state.faqCategory = state.copyFaqCategory
            }
        } else {
            state.faqCategory = state.copyFaqCategory
        }
        // if (filterCategory.length > 0) {
        //     filterFaq = state.copyFaqData.filter(item => item.category === filterCategory[0].id)
        // }
        // state.faqCategory = filterCategory
        // state.faqData = filterFaq
        state.faqData = filterFaq
    },
    SET_FAQ_LIST (state, payload) {
        const filterList = state.copyFaqData.filter(item => item.id === payload.id) 
        state.faqData = filterList
    },
    CATEGORY_FAQ_ALL (state, _) {
        state.faqCategory = state.copyFaqCategory
        state.faqData = state.copyFaqData
    },
    UPDATE_APP_INTEGRATION (state, payload) {
        const appFilter = []
        state.appIntegration = JSON.parse(JSON.stringify(payload))
        state.copyAppIntegration = JSON.parse(JSON.stringify(payload))
        payload.forEach(element => {
            if (!appFilter.includes(element.name)) {
                appFilter.push(element.name)
            }
        })
        state.appFilterState = appFilter
    },
    SUBSCRIPTION_ALL (state, payload) {
        state.allSubscription = payload
    },
    UPDATE_ACCOUNTING_TYPE (state, payload) {
        state.getAccountingType = payload
    },
    UPDATE_DASHBOARD_METRICS (state, payload) {
        state.dashboardMetrics = payload
    },
    UPDATE_NOTIFICATION (state, payload) {
        state.notification = payload
    },
    UPDATE_SYSTEM_LOGS (state, payload) {
        state.sytemAppLogs = JSON.parse(JSON.stringify(payload))
        state.copySystemLogs = JSON.parse(JSON.stringify(payload))

        payload.forEach(element => {
            if (!state.appType.includes(element.app_name)) {
                state.appType.push(element.app_name)
            }
        })
    },
    DEFAULT_SYSTEM_LOG (state, _) {
        state.sytemAppLogs = state.copySystemLogs
    },
    FILTER_SYSTEM_LOGS (state, payload) {
        const filter = state.copySystemLogs.filter(item => item.app_name === payload)
        state.sytemAppLogs = filter
    },
    FILTER_SYSTEM_DATE (state, payload) {
        if (payload !== null) {
            const filter = state.copySystemLogs.filter(item => moment(item.timestamp.split('T')[0], 'YYYY-MM-DD').isBetween(payload[0], payload[1]))
            state.sytemAppLogs = filter
        } else {
            state.sytemAppLogs = state.copySystemLogs
        }
    },
    FILTER_AUDIT_DATE (state, payload) {
        if (payload !== null) {
            const filter = state.copyAppLog.filter(item => moment(item.timestamp).isBetween(payload[0], payload[1]))
            state.AppLogs = filter
        } else {
            state.AppLogs = state.copyAppLog
        }
    },
    DEFAULT_APP_LOGS (state, _) {
        state.AppLogs = state.copyAppLog
    },
    FILTER_USER_LOGS (state, payload) {
        const filter = state.copyAppLog.filter(item => 
            item.username.toLowerCase().includes(payload.target.value.toLowerCase())
        )
        state.AppLogs = filter
    },
    UPDATE_BILLING_HISTORY (state, payload) {
        state.billingHistory = JSON.parse(JSON.stringify(payload))
        state.copyBillingHistory = JSON.parse(JSON.stringify(payload))
    },
    SORT_DATE_ASC (state, _) {
        if (state.billingHistory.Invoices) {
            const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
                return new Date(b.InvoiceDate) - new Date(a.InvoiceDate)
            })
            state.billingHistory.Invoices = filterD
        }
    },
    SORT_DATE_DESC (state, _) {
        if (state.billingHistory.Invoices) {
            const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
                return new Date(a.InvoiceDate) - new Date(b.InvoiceDate)
            })
            state.billingHistory.Invoices = filterD
        }
    },
    SORT_DUE_DATE_ASC (state, _) {
        if (state.billingHistory.Invoices) {
            const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
                return new Date(b.DueDate) - new Date(a.DueDate)
            })
            state.billingHistory.Invoices = filterD
        }
    },
    SORT_DUE_DATE_DESC (state, _) {
        if (state.billingHistory.Invoices) {
            const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
                return new Date(a.DueDate) - new Date(b.DueDate)
            })
            state.billingHistory.Invoices = filterD
        }
    },
    SORT_PRICE_ASC (state, _) {
        if (state.billingHistory.Invoices) {
            const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
                return a.Total - b.Total
            })
            state.billingHistory.Invoices = filterD
        }
    },
    SORT_PRICE_DESC (state, _) {
        if (state.billingHistory.Invoices) {
            const filterD = state.copyBillingHistory.Invoices.sort((a, b) => {
                return b.Total - a.Total
            })
            state.billingHistory.Invoices = filterD
        }
    },
    GET_FILTER_APP_SELECT (state, payload) {
        const filterApp = state.copyAppIntegration.filter(item => 
            parseInt(item.accounting[0]) === parseInt(payload.value)
        )
        state.appIntegration = filterApp
    },
    FILTER_WEBAPPS (state, payload) {
        const filterApp = state.copyAppIntegration.filter(item => 
            item.name.toLowerCase().includes(`${payload.value.toLowerCase()}`)    
        )
        state.appIntegration = filterApp
    },
    SET_USER_COMPANY_ID (state, payload) {
        state.userCompanyID = payload
        setUserCompany(payload, 'company')
    },
    SET_USER_FIRM_ID (state, payload) {
        state.userFirmID = payload
        setUserCompany(payload, 'Firm')
    },
    SET_USER_COMPANY_BILLING (state, payload) {
        state.companyBilling = payload
    },
    SET_USER_COMPANY_PAYMENT_TYPE (state, payload) {
        state.setPaymentType = payload
    },
    UPDATE_OWN_COMPANY (state, payload) {
        state.ownerCompany = payload
    },
    UPDATE_ASSOCIATE_COM (state, payload) {
        state.associateCompany = payload
    },
    UPDATE_USER_INFO (state, payload) {
        state.UserInfo = payload
    },
    REMOVE_COMPANY_USER (state, payload) {
        state.userCompanyID = payload
    }
}
