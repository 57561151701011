<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    import { getLocale, saveLocale } from './services/storage-window'
    import i18n from '@/plugins/i18n'

    export default {
        name : 'root',
        mounted () {
            document.addEventListener('contextmenu', function (e) {
                e.preventDefault()
            })
            if (!getLocale()) {
                // window.localStorage.setItem('xlocale', 'se')
                saveLocale('se')
                this.$services.moment.locale('se')
            } else {
                // i18n.locale = window.localStorage.getItem('xlocale')getLocale
                i18n.locale = getLocale()
                this.$services.moment.locale(i18n.locale)
            }
        }
    }
</script>

<style lang='scss'>
@import "./assets/variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;600&display=swap');

html{
    // font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-family: 'Poppins' !important;
    font-weight: normal !important;
}
::-webkit-scrollbar {
  width: 5px;   /* width of the entire scrollbar */
  overflow-y: overlay;
}

::-webkit-scrollbar-track {
  background: $form-border;        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: $theme-primary-color !important;    /* color of the scroll thumb */
  border-radius: 10px;       /* roundness of the scroll thumb */
//   border: 3px solid orange;  /* creates padding around scroll thumb */
}

* {
  scrollbar-width: thin; 
  scrollbar-color: $theme-primary-color  $form-border;   /* scroll thumb and track */
}
.scrollbar-sidebar::-webkit-scrollbar {
  width: 0px;       /* width of the entire scrollbar */
}
.scrollbar-sidebar{
    scrollbar-width: none !important; 
}

@media only screen and (min-width: 993px) {
    .hide-on-large-only {
        display: none !important;
    }
    .closed-sidebar .app-sidebar{
        width: 80px !important;
        min-width: 80px !important;
    }
    .custom-mt{
        margin-top: 5rem !important;
    }
    label + input.checkbox-height[type='checkbox'] {
        /* float: left !important; */
        // margin-left: -9rem;
    }
    .min-h-reg{
        min-height: 170px !important;
    }
    .fixed-sidebar .single-main .app-main__outer{
        padding-left: 200px !important;
    }
    .r-min-h{
        min-height: 124px !important;
    }
    .width-div-mobile {
        min-width: 25rem !important;
    }
    .right-md{
        right: 5px;
    }
    .w-c65{
        width: 65% !important;
    }
    .h-c32{
        height: 32px !important;
    }
    .h-c28{
        height: 28px !important;
    }
    .h-c33{
        height: 33px !important;
    }
}

 @media only screen and (max-width: 992px) {
    .width-div-mobile {
        min-width: 21rem !important;
    }
    .right-md{
        right: 50px;
    }
    .notifiy-drawer-style{
        width: 90% !important;
    }
    .system-maintain-top{
        top:120px !important;
    }
    .system-main {
        padding-top: 240px !important;
    }
}
.no-deco{
    text-decoration: none !important;
}
.closed-sidebar:not(.closed-sidebar-mobile) .app-header .app-header__logo {
    width: 80px !important;
}
.closed-sidebar.fixed-sidebar .single-main .app-main__outer {
    padding-left: unset !important;
}

.closed-sidebar.fixed-sidebar .accountant-main .app-main__outer {
    padding-left: unset !important;
}
.select2-container--default .select2-selection--single{
    border: 1px solid $form-border !important;
}
.form-config > .select2-container{
    width: 100% !important;
}
.optional-config-form > .select2-container{
    width: 100% !important;
}
.app-header__logo{
    width: 200px !important;
}
.font-bold{
    font-weight: 800;
}
.fixed-sidebar .accountant-main .app-main__outer{
    padding-left: unset !important;
}

/* .single-main {

} */
.closed-sidebar.fixed-sidebar .app-main__outer {
    padding-left: 80px !important;
}
.app-sidebar{
    width: 200px !important;
    min-width: 200px !important;
}
.header-user-name{
    font-size: 0.8rem !important;
}
.card-text{
    font-size: 1.1rem !important;
}
.heading-text{
    font-size: 1.2rem !important;
}
.vertical-nav-menu i.metismenu-icon {
    font-size: 1rem !important;
}
.small-icon{
    font-size: 1rem !important;
}
.min-h{
    min-height: 200px !important;
}
.cursor{
    cursor: pointer;
}
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
.form-input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  /* background-color: #fff; */
  /* border-color: #e2e8f0; */
  border-width: 1px;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.form-input::-webkit-input-placeholder {
  color: #a0aec0;
  opacity: 1;
}

.form-input::-moz-placeholder {
  color: #a0aec0;
  opacity: 1;
}

.form-input:-ms-input-placeholder {
  color: #a0aec0;
  opacity: 1;
}

.form-input::-ms-input-placeholder {
  color: #a0aec0;
  opacity: 1;
}

.form-input::placeholder {
  color: #a0aec0;
  opacity: 1;
}

.form-input:focus {
  outline: none;
  /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
  /* border-color: #63b3ed; */
}
.checkbox-height {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1em;
  width: 1em;
  color: $theme-primary-color;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  border-radius: 0.25rem;
}

.checkbox-height:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media not print {
  .checkbox-height::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}

.checkbox-height:focus {
  outline: none;
  box-shadow: 0 0 0 3px $theme-primary-color;
  border-color: $form-border;
}


.input-shadow-box{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.form-bg{
    background: #F1F6F6 !important;
}
.b-radius{
    border-radius: 8px !important;
}

.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  background-repeat: no-repeat;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  background-position: right 0.5rem center;
  background-size: 1.5em 1.5em;
}
.override-height {
    height: unset !important;
}
.color-custom-head{
    color: #344258 !important;
    /* color: #0c1427 !important; */
}
.btn-custom {
    background-color: rgba(70, 194, 172, 1) !important;
    border-color: rgba(70, 194, 172, 1) !important;
    color: #FFFFFF;
}
.btn-theme-custom {
    background-color: #0DB23C !important;
    border-color: #0DB23C !important;
    color: #FFFFFF;
}
.btn-theme-amazon {
    background-color: #db880c !important;
    border-color: #db880c !important;
    color: #FFFFFF;
}
.link-color {
    color:rgba(102, 113, 216, 1);
}
.link-theme-color{
    color: #1E2C41 !important;
}
.help-color{
    color: #546295 !important;
}
.no-de-hover:hover{
    color: rgba(102, 113, 216, 1) !important;
}
.secondary-color {
    color: rgba(70, 194, 172, 1) !important;
}
.paragraph-color {
    color: rgba(69, 79, 90, 1) !important;
}
.label-context{
    white-space: nowrap;
    font-size: 20px !important;
    color: #000;
    font-weight: 800;
}
.label-context-reg{
    white-space: nowrap;
    font-size: 18px !important;
    color: #000;
    font-weight: 800;
}
.house-theme-head{
    color: #7E3469 !important;
}
.house-theme-sm{
    color: #71429F !important;
}
.house-theme-sm-head{
    color: #3A71C2 !important;
}
.no-profile-img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    // background: #512DA8;
    font-size: 35px;
    color: #fff;
    text-align: center;
    line-height: 150px;
    margin: 20px 0;
    p {
        margin-top: -52px;
        color: #fff;
        font-size: 17px;
    }
}

#sticky-text-area::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
#sticky-text-area::-webkit-scrollbar-thumb {
    background: transparent;
}
// *::-webkit-scrollbar {
//   width: 0px;
//   background: #000;
// }
#sticky-text-area::-webkit-scrollbar-track {
    background: #ddd;
//   background: transparent;
}
.bg-stick{
    background: #EFEFD6;
}
.el-loading-mask {
    .el-loading-spinner{
        display: flex;
        justify-content: center;
    }
}
.icon-color{
    color : #200E32 !important;
}
.table-head-color{
    color: #3A71C2 !important;
}
.text-sm-app {
    font-size: 0.775rem;
    line-height: 1.25rem;
}
.notification-container{
    bottom: unset !important;
    top: 60px !important;
}
.notify-me:first-child {
    margin-bottom: 0;
    width: 300px;
}
.notify-me {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    bottom: 2rem;
    right: 2rem;
    z-index: 9999;
    margin-bottom: 1.5rem;
}
.notification {
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
    position: relative;
}
.notification.is-danger {
    background-color: #e62968;
    color: #fff;
}
.notification.is-success {
    background-color: #33cc85;
    color: #fff;
}
.notification.is-info {
    background-color: #33a3e8;
    color: #fff;
}
.flag.small {
    height: 15px !important;
    width: 15px !important;
    background-size: 15px 15px;
}
.lg-icon{
    font-size: 1.5rem !important;
}
.c-pad-setting{
    padding: 0.175rem .75rem !important;
}
.company-select{
    .el-input{
        .el-input__inner{
            // height: !important;
        }
    }
}
.app-input.el-input__inner{
    height: unset !important;
}
.el-range-editor.el-input__inner{
    padding: 0px 10px !important;
}
.el-range-editor.el-input__inner{
    padding: 0px 10px !important;
    // padding-top: unset !important;
}
.text-xs-custom {
    font-size: 10px !important;
    line-height: 1rem !important;
}
.text-xs {
    font-size: 0.8rem !important;
    line-height: 1rem !important;
}
.text-xs-menu{
    font-size: 0.6rem !important;
}
.pagination li a{
    padding: .2rem .75rem !important;
}
.header-dots .icon-wrapper-alt{
    height: 35px !important;
    width: 35px !important;
}
.dropdown-menu-header .dropdown-menu-header-inner{
    padding: 0.2rem .5rem !important;
}
.app-theme-gray .app-header .app-header__logo{
    border-right: unset !important;
}
.c-pad-setting{
    border: 1px solid $form-border !important;
}
.c-pad-setting:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.checkbox-height:focus{
    box-shadow: unset !important;
    color: $theme-primary-color !important;
    border: 1px solid $theme-primary-color !important;
}
.o-pad{
    padding: unset !important;
}
.note.neutral{
    border-radius: 10px !important;
    background-color: #EFEFD6 !important;
    box-shadow: 0px 7px 30px rgba(34, 45, 57, 0.16) !important;
}
.ql-container.ql-snow{
    height: 200px;
}
// Tooltip
.tooltip-settings {
  position: relative;
  display: inline-block;
//   border-bottom: 1px dotted black;
}

.tooltip-settings .tooltiptext {
  visibility: hidden;
//   width: 360px;
  background-color: #ffff;
//   background-color: #1E2C41;
  color: #1E2C41;
//   text-align: center;
  border-radius: 6px;
  padding: 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}       

.tooltip-settings:hover .tooltiptext {
  visibility: visible;
}
.vertical-timeline::before{
    background :#EE4D81 !important;
}
.dropdown-menu.dropdown-menu-xl{
    min-width: 34rem !important;
}
.select2-container--default .select2-results>.select2-results__options {
    overflow-x: clip;
}
.select2-search--dropdown, .select2-container--default .select2-search--dropdown .select2-search__field{
    &:focus-visible{
        outline: none !important;
    }
    &:focus{
        outline: none !important;
    }
}
.am-theme-btn{
    height: 72px;
    width: 258px;
    left: 325px;
    top: 389px;
    border-radius: 8px;
}
.k-progress-outer{
    padding-right: unset !important;
} 
.unset-mb{
    margin-bottom: unset !important;
}
</style>