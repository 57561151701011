import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import AppRoutes from './routes'
import store from './../store'

let routes = []
routes = routes.concat(AppRoutes)

Vue.use(VueRouter)


const routerPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(_ => console.log('terminated'))
}

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
})

const auth = {
    loggedIn () {
        return store.getters['auth/USER_TOKEN']
    }
}



router.beforeEach((to, from, next) => {
    window.document.title = to.meta && to.meta.title ? to.meta.title : 'Synkio'

    if (to.matched.some(record => record.meta.requireAuth)) { 
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (auth.loggedIn() === null || auth.loggedIn() === undefined) {
            next({
                name : 'login'
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
    next()
})

export default router
