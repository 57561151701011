export default {
    MODAL_INVITE (state) {
        return state.model_invite
    },
    MODAL_PHOTO (state) {
        return state.model_photo
    },
    MODAL_UTIL (state) {
        return state.model_util
    },
    GET_NOTIFICATION_DRAWER (state) {
        return state.notificationDrawer
    },
    MODAL_IN_HOUSE_FAQ (state) {
        return state.modal_inhouse
    },
    GET_LOCALE_LANG (state) {
        return state.setLocale
    }
}