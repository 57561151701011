import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { getToken } from './../services-jwt'

Vue.use(VueAxios, axios)

const downloadHTTP = Vue.axios.create({
    baseURL : process.env.VUE_APP_BASE_URL_EXTERNAL,
    responseType: 'blob',
    headers : {
        'Content-Type' : 'application/pdf',
    }
})

downloadHTTP.interceptors.request.use(    
    function (req) {
        const token = `${getToken()}`
        if (token) { req.headers.common.Authorization = `Bearer ${token}` }
        return req
    },
    function (err) {
        return Promise.reject(err)
    }
)


export default downloadHTTP

