<template>
    <notify-me
        :event-bus="bus"
        >
        <template slot="content" slot-scope="{data}" class="shadow-lg">
            <div style="width: 100%; text-align: left">
                <h4><b>{{data.title}}</b></h4>
                <p  class="font-light text-xs word-breaking">{{data.text}}</p>
            </div>
        </template>
  </notify-me>
</template>

<script>
    import Notify from 'vue-notify-me'
    import bus from './../bus'
    

    export default {
        name : 'notification-alert',
        components : {
            'notify-me' : Notify
        },
        data () {
            return {
                bus
            }
        }
    }
</script>

<style lang="scss" scoped>
.z-index-notification{
    z-index: 999999;
}
.word-breaking{
    overflow-wrap: anywhere !important;
}
</style>