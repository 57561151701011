const SYCKIO_TOKEN = 'synkio'
const SYCKIO_REFRESH_TOKEN = '__syn__'
const IS_ACCOUNTANT = 'util'
const IS_IN_HOUSE_USER = 'reghouse'

export const saveToken = token => {
    window.localStorage.setItem(SYCKIO_TOKEN, token.access)
}

export const saveRefreshToken = token => {
    window.localStorage.setItem(SYCKIO_REFRESH_TOKEN, token.refresh)
}

export const saveIsAccountant = data => {
    window.localStorage.setItem(IS_ACCOUNTANT, data.user.is_accountant)
}

export const getIsAccountant = () => {
    return window.localStorage.getItem(IS_ACCOUNTANT)
}

export const saveIsHouse = data => {
    window.localStorage.setItem(IS_IN_HOUSE_USER, data.user.is_staff)
}

export const getIsHouse = () => {
    return window.localStorage.getItem(IS_IN_HOUSE_USER)
}

export const saveUser = (data) => {
    saveToken(data)
    saveRefreshToken(data)
    // if (data.user) {
    //     if (data.user.is_accountant) {
    //         saveIsAccountant(data)
    //     }
    //     if (data.user.is_staff) {
    //         saveIsHouse(data)
    //     }
    // }
}

export const saveUserTokenRefresh = (data) => {
    window.localStorage.setItem(SYCKIO_TOKEN, data)
}

export const getToken = () => {
    return window.localStorage.getItem(SYCKIO_TOKEN)
}

export const getRefreshToken = () => {
    return window.localStorage.getItem(SYCKIO_REFRESH_TOKEN)
}

export const destroyToken = () => {
    window.localStorage.removeItem(SYCKIO_TOKEN)
    window.localStorage.removeItem(SYCKIO_REFRESH_TOKEN)
    window.localStorage.clear()
}

export default { saveToken, getToken, saveUserTokenRefresh, saveRefreshToken, saveUser, getIsAccountant, getIsHouse, getRefreshToken, destroyToken }