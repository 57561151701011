// import Vue from 'vue'
import { extend, configure } from 'vee-validate'
import { required, email, min, max } from 'vee-validate/dist/rules'
import i18n from '@/plugins/i18n'

configure({
    defaultMessage: (field, values) => {
      // override the field name.
      values._field_ = i18n.t(`fields.${field}`)
  
      return i18n.t(`validation.${values._rule_}`, values)
    }
})

// Install required rule.
extend('required', required)

// Install email rule.
extend('email', email)

// Install min rule.
extend('min', min)

// Install max rule
extend('max', max)


extend('minmax', {
    validate (value, { min, max }) {
      return value.length >= min && value.length <= max
    },
    params: ['min', 'max']
})

extend('minmaxuser', {
    validate (value, { min, max }) {
      return value.length >= min && value.length <= max
    },
    params: ['min', 'max']
})

extend('minmaxpass', {
    validate (value, { min, max }) {
      return value.length >= min && value.length <= max
    },
    params: ['min', 'max']
})

extend('validcom', {
    validate (value) {
        if (value.split('-')[0] !== undefined && value.split('-')[1] !== undefined) {
            if (value.split('-')[0].length === 6 && 
                value.split('-')[1].length === 4 && 
                parseInt(value.split('-')[0]) && 
                parseInt(value.split('-')[1])) {
                return true
            }
        }
        return false
    },
})
