import bus from './../../bus'

export default { 
    notification (message, status, _) { 
        if (status === 'success') { 
            bus.$emit('notify-me', {
                permanent: false,
                status: 'is-success shadow-lg rounded',
                data: {
                    id : uid('d'),
                    title: 'Success',
                    text: message
                }
            })
        } else if (status === 'error') {
            bus.$emit('notify-me', {
                permanent: false,
                status: 'is-danger shadow-lg rounded',
                data: {
                    id : uid('d'),
                    title: 'Error',
                    text: message
                }
            })
        } else {
            bus.$emit('notify-me', {
                permanent: false,
                status: 'is-info shadow-lg rounded',
                data: {
                    id : uid('d'),
                    title: 'Processing',
                    text: message
                }
            })
        } 
    },
    validateEmailAddress (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    },
    capitalizeEachString (name) {
        return name !== null ? name.split(' ').map(v => typeof v !== 'string' ? v + ' ' : v.charAt(0).toUpperCase() + v.toLowerCase().slice(1) + ' ').join('').trim() : name
    },
    removeUnderscores (name) {
        const newName = name.replace('_', ' ')
        return this.capitalizeEachString(newName)
    },
    animateValue : (obj, start, end, duration) => {
        // console.log('obj:', document.getElementById(obj))
        if (document.getElementById(obj) !== null) {
            let startTimestamp = null
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp
                const progress = Math.min((timestamp - startTimestamp) / duration, 1)
                if (document.getElementById(obj)) {
                    document.getElementById(obj).innerHTML = Math.floor(progress * (end - start) + start)
                    if (progress < 1) {
                        window.requestAnimationFrame(step)
                    }
                }
            }
            window.requestAnimationFrame(step)
        }
    },
    allCurrencies () {
        return [
            'EUR', 'GBP', 'AUD', 'NZD', 'USD', 'CAD', 'CHF',
            'NOK', 'SEK', 'DKK', 'JPY', 'CNY', 'KRW', 'SGD', 'IDR', 'THB',
            'TWD', 'HKD', 'PHP', 'INR', 'MYR', 'VND', 'BRL', 'PEN', 'ARS',
            'MXN', 'CLP', 'COP', 'JMD', 'TTD', 'BMD', 'CZK', 'PLN', 'TRY',
            'HUF', 'RON', 'RUB', 'HRK', 'KZT', 'BGN', 'ILS', 'AED', 'QAR',
            'SAR', 'EGP', 'NGN', 'ZAR', 'XBT'
        ]
    }
}

function uid (e) {
    e = {}
    if (e.uid) return e.uid
    const key = Math.random()
        .toString(16)
        .slice(2)
    e.uid = key
    return e.uid
}