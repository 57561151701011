import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

const HTTPEntry = Vue.axios.create({
    baseURL : process.env.VUE_APP_BASE_URL_EXTERNAL,
    headers : {
        'Content-Type' : 'application/json',
        Accept : 'application/json'
    }
})


export default HTTPEntry

