
export default {
    GET_USER : 'profiles/',
    AUTH_USER_LOGIN : 'login/',
    AUTH_USER_REGISTER : 'auth/signup/',
    FORGET_USER_PASSWORD : 'forgot_password/',
    RESET_USER_PASSWORD : 'reset_password/',
    REFRESH_USER_TOKEN : 'token/refresh/',
    APP_CONNECT : 'connections/',
    GET_LOGS : 'logs/',
    GET_USER_NOTIFICATION : 'notifications/',
    USER_COMPANY : 'companies/', 
    USER_ROLE : 'user/role/',
    USER_PROFILE : 'profiles/',
    // USER_COMPANY : 'companies/',
    USER_SUBSCRIPTION : 'user/subscription/',
    FAQ_DATA_ENDPOINT : 'faqs/',
    GET_FAQ_CATEGORY_LIST : 'faqs/all/categories/',
    AVAILABLE_APPS : 'apps/available/',
    CONNECTION_ENDPOINT : 'connections/',
    SUBSCRIPTION_ENDPOINT : 'subscriptions/',
    TRIGGER_ENDPOINT : 'triggers/',
    CHECK_CONNECTION : 'credential/check/connected',
    ROLE_ENDPOINT : 'roles/',
    












    GET_PRODUCT_LIST : 'products/',
    GET_APP_LIST : 'apps/',
    GET_USER_APP_CONFIG : 'user/subscriptions/config/',
    GET_AUTH_USER : 'user/',
    INVITE_SET_PASSWORD : 'invitee_set_password/',
    GET_USER_TRANSACTION : 'user/transactions/',
    GET_USER_COMPANY : 'user/company/',
    GET_USER_SUBSCRIPTION : 'user/subscriptions/',
    GET_USER_BY_MANAGER : 'accountant/users/',
    GET_USER_SWITCH_PAYMENT : 'user/switch_payment_method/card/',
    GET_USER_SWITCH_INVOICE : 'user/switch_payment_method/invoice/',
    GET_USER_SWITCH_YEARLY : 'user/switch_billing_cycle_yearly/',
    READ_NOTIFICATION : 'user/notification/',
    GET_BILLING_HISTORY : 'user/invoices/',
    GET_PAYMENT_HISTORY : 'user/payment_history/',
    GET_USER_INITIALIZE_PAYMENT : 'user/initiate_payment_method_change/card/',
    ACCOUNTANT_INVITE : 'user/invite_accountant/',
    ACCOUNTANT_INVITE_COM : 'accountant/invite_company/',
    GET_FAQ_LIST : 'faq/',
    GET_FAQ_BY_CATEGORY : 'faq/category/',
    GET_FAQ_CATEGORY_GROUP : 'faq/category/group/',
    GET_SYSTEM_LOGS : 'logs/',

    // App settings endpoints
    GET_HELP_CONFIG_TEXT : 'config/help_text/',
    GET_READ_INFO_SUBSCRIPTION : 'user/subscriptions/info/',
    ACTIVATE_SUBSCRIPTION : 'user/subscriptions/activate/',
    TEST_SUBSCRIPTION : 'user/subscriptions/test/',
    CREATE_BAMBOO_MAPPING : 'create_bamboohr_mapping/',
    DELETE_BAMBOO_DATA : 'delete_bamboohr_mapping/',
    GET_BAMBOOHR_TYPES : 'get_bamboohr_map_types/',
    GET_FORTNOX_CAUSE_CODE : 'get_bamboohr_fortnox_cause_code/',
    GET_BAMBOOHR_TIME_OFFS : 'get_bamboohr_timeoff_types/',
    GET_BAMBOOHR_EMP_FIELDS : 'get_bamboohr_employee_fields/',
    GET_BAMBOOHR_MAP_DATA : 'get_bamboohr_map_data/',
    SHOPIFY_AUTH : 'shopify_auth/',
    STRIPE_AUTH : 'stripe_auth/',
    GET_CUSTOM_ACCOUNT_SUB : 'get_custom_accounts/',
    GET_COUNTRIES : 'get_country_codes/',
    GET_ACCOUNT_TYPES : 'get_account_types/',
    GET_MATCH_TYPE : 'get_match_types/',
    CREATE_CUSTOM_ACCOUNT : 'create_custom_accounts/',
    DELETE_CUSTOM_ACCOUNT : 'delete_custom_accounts/',
    SWISH_HANDEL_FILE : 'upload_swish_handel_file/',
    ADYEN_PLUS_FILE : 'upload_adyen_plus_file/',
    GET_WAYS_OF_DELIVERY_OPTIONS : 'user/get_way_of_delivery_options/',
    GET_WAYS_OF_DELIVERY_SUBSCRIPTION : 'get_ways_of_delivery_subscription/',
    GET_TERMS_OF_DELIVERY_OPTIONS : 'user/get_term_of_delivery_options/',
    GET_PAYMENT_TERM_OPTIONS : 'get_payment_term_options/',
    GET_PAYMENT_TERMS_SUBSCRIPTION : 'get_payment_term_subscription/',
    CREATE_WAY_OF_DELIVERY_SUBSCRIPTION : 'create_way_of_delivery_subscription/',
    CREATE_PAYMENT_TERM_SUBSCRIPTION : 'create_payment_term_subscription/',
    DELETE_WAYS_OF_DELIVERY_SUBSCRIPTION : 'delete_way_of_delivery_subscription/',
    DELETE_PAYMENT_TERMS_SUBSCRIPTION : 'delete_payment_term_subscription/',
    PAYMENT_PROVIDER : 'get_payment_providers/',
    GET_ACTIVE_ACCOUNT_NUMBERS : 'get_active_account_numbers/',
    ADD_ACCOUNT_NUMBERS : 'add_account_numbers/',
    GET_VOUCHER_SERIES : 'get_voucher_series/',
    GET_PRICE_LIST : 'get_price_list/',
    VISMA_AVAILABILITY : 'get_visma_availability/',

    APP_GUIDE : 'accountant/users/',
    IN_HOUSE_FIRMS : 'in_house/get_all_accounting_firms/',
    IN_HOUSE_COMPANIES : 'in_house/get_all_companies/',
    IN_HOUSE_SINGLE_FIRM : 'in_house/get_accountants_in_firm/',
    IN_HOUSE_SUMMARY : 'in_house/app_summary/',
    IN_HOUSE_AUDIT_LOGS : 'all_audit_logs/',
    IN_HOUSE_GET_FIRM_COMPANIES : 'in_house/get_companies_under_firm/',
    IN_HOUSE_UPDATE_FIRM : 'accounting_firm/',
    IN_HOUSE_REASSIGNING : 'reassign_accountant_clients/',
    MANAGERS_NOTE : 'user/notes/',
    UPLOAD_USER_PHOTO : 'upload_profile_pic/',    
    CREATING_FAQ : 'faq/create/',
    UPLOAD_FAQ_IMAGE : 'upload_file_amfront/',
    GET_ACCOUNTING_PLATFORM : 'get_accounting_platforms/',

    // Metrics
    GET_COMPANY_METRICS : 'am_metric/company/',
    GET_ACCOUNTING_FIRM_METRICS : 'am_metric/accounting_firm/',
    GET_ACCOUNTANT_METRICS : 'am_metric/accountant/',
    GET_IN_HOUSE_METRICS : 'am_metric/in_house/',
    GET_METRICS_GRAPH : 'am_metric/graph/'
}   
