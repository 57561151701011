import state from '../../customer/state'
import { saveUser, destroyToken, saveUserTokenRefresh } from './../../../../services/services-jwt'
import { deleteUserData } from './../../../../services/storage-window'

export default {
    UPDATE_USER_TOKEN (state, payload) {
        state.token = payload.access
        state.refreshToken = payload.refresh
        saveUser(payload)
    },
    UPDATE_USER_TOKEN_AFTER_REFRESH (state, payload) {
        state.token = payload.access
        saveUserTokenRefresh(payload.access)
    },
    CLEAR_AUTH_USER (state, payload) {
        state.token = payload
        destroyToken()
        deleteUserData()
    },
}

// function storeUSER (data) {
//     window.localStorage.setItem('amdjango', data.access)
//     window.localStorage.setItem('amre', data.refresh)
//     if (data.user.is_accountant) {
//         window.localStorage.setItem('util', data.user.is_accountant)
//     }
//     if (data.user.is_in_house_user) {
//         window.localStorage.setItem('reghouse', data.user.is_in_house_user)
//     }
// }

// function setUserCompany (data, entity) {
//     if (entity === 'Firm') {
//         window.sessionStorage.setItem('xfirm', JSON.stringify(data))
//     } else {
//         window.sessionStorage.setItem('xmdjango', data)
//     }
// }

// function setLocale (payload) {
//     window.localStorage.setItem('xlocale', payload)
// }

// function clearToken () {
//     window.localStorage.removeItem('amdjango')
//     window.localStorage.removeItem('amre')
//     window.localStorage.removeItem('util')
//     window.localStorage.removeItem('reghouse')
//     window.sessionStorage.removeItem('xmdjango')
//     window.sessionStorage.removeItem('xfirm')
//     window.localStorage.removeItem('randomc')
// }