<template>
    <div>
        <div class="bounce-in p-3 font-light text-xs mb-1" :class="notify.is_read === false ? 'bg-gray-100' : ''" v-if="archive && notify.is_read === true"> <!--:class="notify.read === false ? 'bg-gray-100' : ''"-->
            <h4 class="timeline-title font-medium">{{ notify.subject }}</h4>
            <div>
                <span v-if="open">{{ notify.content }}</span>
                <span v-else>{{ notify.content.slice(0, 80) + '...' }}</span>

                <span @click="openMessage" v-if='open' class="cursor-pointer font-semibold link-color"> {{ $t('less') }}</span>
                <span @click="openMessage" class="cursor-pointer font-semibold link-color" v-else> {{ $t('more') }}</span> 
                <div class="flex justify-between mt-2">
                    <div>
                        <span v-if="notify.redirect_url">
                            <a class="text-blue-600 no-deco" :href="notify.redirect_url" target="_blank">{{ $t('notifyRedirect') }}</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bounce-in p-3 font-light text-xs mb-1" :class="notify.read === false ? 'bg-gray-100' : ''"  v-else> <!--:class="notify.read === false ? 'bg-gray-100' : ''"-->
            <h4 class="timeline-title font-medium">{{ notify.subject }}</h4>
            <div>
                <span v-if="open">{{ notify.content }}</span>
                <span v-else>{{ notify.content.slice(0, 80) + '...' }}</span>

                <span @click="openMessage" v-if='open' class="cursor-pointer font-semibold link-color"> {{ $t('less') }}</span>
                <span @click="openMessage" class="cursor-pointer font-semibold link-color" v-else> {{ $t('more') }}</span> 
                <div class="flex justify-between mt-2">
                    <div>
                        <span v-if="notify.redirect_url">
                            <a class="text-blue-600 no-deco" :href="notify.redirect_url" target="_blank">{{ $t('notifyRedirect') }}</a>
                        </span>
                    </div>

                    <span @click="readNotification(notify.id)" class="font-semibold mt-2Läs mer cursor-pointer flex justify-end"> <!--v-if="!notify.read"-->
                        <span v-if="marking">{{ $t('pWait') }}</span>
                        <span v-else class="text-blue-600">{{ $t('markRead') }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import bus from './../bus'

    export default {
        name : 'notification-handler',
        props : {
            notify : {
                type : Object,
                required : true
            },
            archive : {
                type : Boolean,
                required : false
            }
        },
        data () {
            return {
                open : false,
                marking : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
            })
        },
        methods : {
            openMessage () {
                this.open = !this.open
            },
            showAllMessage () {
                let text = ''
                if (this.open) {
                    text = this.notify.content
                } else {
                    text = this.notify.content.slice(0, 20).join(' ') + '...'
                }
                return text
            },
            readNotification (notifyId) {
                this.marking = true
                // const URL = userID ? this.$services.endpoints.GET_USER_NOTIFICATION + `${userID}/` : this.$services.endpoints.GET_USER_NOTIFICATION
                const URL = this.$services.endpoints.READ_NOTIFICATION + `read/${notifyId}/`
                // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
                // this.$services.axios.get(URL)
                this.$store.dispatch('customer/readNotification', { URL })
                .then(res => {
                    this.marking = false
                    console.log(res)
                    if (res.data.success) {
                        bus.$emit('reload-notify')
                        this.$services.helpers.notification(res.data.data, 'success', this)
                    }
                }).catch(err => {
                    this.marking = false
                    console.log(err.response)
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            console.log('it is expired')
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                 this.readNotification(notifyId)
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                // window.location.reload()
                            })
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>