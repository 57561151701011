<template>
    <div class="app-container body-tabs-shadow">
        <div class="container">
            <div class="">
                <div class="d-flex">
                    <div class="text-center mt-4 float-left">
                        <!-- <img class="img-fluid h-12 logo-top pl-5" src="./../../assets/images/header_logo.png" alt=""> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-8">
                        <div class="md:ml-32 w-1/2">
                            <!-- <img class="img-fluid d-none d-lg-block" src="./../../assets/images/illus/auth.svg" alt="authentication"> -->
                            <!-- <h3 class="text-gray-800 whitespace-nowrap mt-10 md:-mt-0 font-semibold text-2xl">{{ $t('loginHeader') }}</h3> -->
                        </div>
                    </div>
                    <div class="col-md-4 md:mt-10">
                        <div class="login-border-section md:mt-20">
                            <ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
                            <form class="mt-5 mb-2" @submit.prevent="handleSubmit(loginUser)">
                                <!-- <div class="form-group">
                                    <label for="exampleEmail" class="override-pb override-pt col-form-label label-context -mt-3"></label>
                                    <div>
                                        <div class="font-semibold text-2xl link-color whitespace-nowrap">{{ $t('loginHeader') }}</div><br>
                                    </div>
                                </div> -->

                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base font-medium">{{ $t('emailUsername') }}</label>
                                    <div>
                                        <ValidationProvider name="emailUsername" rules="required" v-slot="{ errors }">
                                            <input v-model="email" type="text" class="form-control font-light text-xs override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail" class="text-base font-medium">{{ $t('password') }}</label>
                                    <div>
                                        <ValidationProvider name="password" rules="required" v-slot="{ errors }">
                                            <input v-model="password" type="password" class="form-control font-light text-xs override-form-field-l" required>
                                            <small class="text-red-500">{{ errors[0] }}</small>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="exampleEmail"></label>
                                    <div>
                                        <button data-cy="loginBtn" class="w-full font-semibold shadow btn btn-custom btn-primary btn-lg btn-wide round-btn custom-btn-color" type="submit" :disabled="invalid || processing">
                                            <span v-if="processing">Loggar in..</span>
                                            <span v-else>{{ $t('login') }}</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group mt-1">
                                    <label for="exampleEmail" class="override-pb override-pt col-form-label label-context"></label>
                                    <div>
                                        <span class="font-md-login">{{ $t('forgetPassword') }}? <router-link :to="{name : 'forget'}" class="no-deco no-de-hover link-color">{{ $t('reset') }}</router-link></span><br><br>
                                        <span class="font-md-login">{{ $t('notCustomer') }}? <router-link :to="{name : 'register'}" class="no-deco no-de-hover link-color">{{ $t('welcome') }}</router-link></span>
                                    </div>
                                </div>
                                <div class="form-group mt-10">
                                  
                                </div>
                            </form>
                            </ValidationObserver>
                            <!-- <div class="form-group">
                                <label for="exampleEmail" class="col-sm-3 override-pb override-pt col-form-label label-context"></label>
                                <div class="col-sm-9 pl-4 text-center">
                                    <div class="md:ml-5 font-semibold text-xl text-gray-900 whitespace-nowrap">{{ $t('golf') }}</div><br>
                                    <a target="_blank" href="https://golf.automatiseramera.se/login/" class="btn w-3/5 md:ml-10 text-white btn-custom btn-primary btn-lg btn-wide round-btn custom-btn-color">
                                        {{ $t('login') }}
                                    </a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="container font-light text-xs text-center md:bottom-0 md:absolute mb-4">
                    <span>Copyright © <span>{{ current }} Automatisera Mera</span></span>
                    <span> {{ $t('switch') }} 
                        <a @click="changeLocale('en')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-if="GET_LOCALE_LANG === 'se'">{{ $t('english') }}</a>
                        <a @click="changeLocale('se')" href="javascript:void(0);" class="font-bold no-deco no-de-hover link-color" v-else>Swedish</a>
                    </span>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    // import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import { ValidationProvider, ValidationObserver, localeChanged } from 'vee-validate'
    import i18n from '@/plugins/i18n'
    import Notification from './../../customComponent/NotificationAlert'
    import bus from '../../bus'
    export default {
        name : 'login',
        components : {
            Notification,
            ValidationProvider,
            ValidationObserver
        },
        data () {
            return {
                current : new Date().getFullYear(),
                baseURL : window.location.origin,
                loaded : false,
                email : '',
                password : '',
                processing : false,
                bus
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG'
            })
        },
        mounted () {
            if (this.USER_TOKEN) {
                this.$router.replace({ name : 'dashboard' })
            }
        },
        beforeMount () {
            // If user token is set, proceed to dashboard
            if (this.USER_TOKEN) {
                this.$router.replace({ name : 'dashboard' })
            }
        },
        methods : {
            // eventBus : () => bus,
            loginUser () {
                if (this.email === '' || this.password === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else {
                    this.processing = true
                    this.$store.dispatch('auth/loginUser', { username : this.email, password : this.password })
                    .then(res => {
                        this.processing = false
                        this.$services.helpers.notification(this.$t('loginSuccess'), 'success', this)
                        this.setRandomColor()

                        setTimeout(() => {
                            this.$router.replace({ name : 'appsettings' })
                        }, 2000)
                    }).catch((err) => {
                        // console.log('Look:', err.response)
                        this.processing = false
                        // if (err.response.status === 400) {
                        //     this.$services.helpers.notification(Object.values(err.response.data.data), 'error', this)
                        // }
                        if (err.response.status === 401) {
                            this.$services.helpers.notification(err.response.data.detail, 'error', this)
                        }
                    })
                }
            },
            changeLocale (locale) {
                i18n.locale = locale
                this.$store.commit('utility/CHANGE_LOCALE', locale)
                localeChanged()
            },
            setRandomColor () {
                const color = ['#512DA8', '#7E3469', '#EE4D81', '#344258', '#546295', '#1E2C41']
                const choose = color[Math.floor(Math.random() * color.length)]
                if (!window.localStorage.getItem('randomc')) {
                    window.localStorage.setItem('randomc', choose)
                }
                return false
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
#app{
    overflow-y: hidden !important;
}
.login-head-text{
    font-size: 1.2rem !important;
}
.login-head-text-xl{
    font-size: 1.5rem !important;
}
.override-form-field{
    border: 0;
    border-bottom: 1px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: unset !important;
    border-radius: unset !important;
}
html, body{
    background: #fff !important;
    overflow: hidden;
}
.override-pt{
    padding-top: 1.2rem !important;
}
.override-pb{
    padding-bottom: unset !important;
}
.custom-rem{
    padding: 5rem;
}
.form-group{
    margin-bottom: 0.5rem !important;
}
.form-control{
    border: 1px solid $form-border !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
</style>