<template>
    <div id="header-holder" class="app-header header-shadow">
            <div class="app-header__logo">
                <div data-cy='logo-sr' class="logo-sr">
                    <img data-cy='img-fluid' class="img-fluid h-5 logo-top" :src="baseURL+'/assets/images/Automatisera/header_logo.png'" alt="">
                </div>
                <div class="header__pane ml-auto">
                    <!-- <div>
                        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
                        </button>
                    </div> -->
                </div>
            </div>
            <div class="app-header__mobile-menu">
                <div>
                    <button type="button" id="hamburger-btn" @click="toggleSidebar" class="hamburger hamburger--elastic mobile-toggle-nav">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
            <div class="app-header__menu">
                <span>
                    <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav" @click="toggleNavTop">
                        <span class="btn-icon-wrapper">
                            <i class="fa fa-ellipsis-v fa-w-6"></i>
                        </span>
                    </button>
                </span>
            </div>    
            <div class="app-header__content" id="div-open" data-cy='div-open'>
                <div class="app-header-left">
                    <!-- <div class="search-wrapper">
                        <div class="input-holder">
                            <input type="text" class="search-input" placeholder="Type to search">
                            <button class="search-icon"><span></span></button>
                        </div>
                        <button class="close"></button>
                    </div> -->
                    <ul class="header-megamenu nav">
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0);" data-placement="bottom" rel="popover-focus" data-offset="300" data-toggle="popover-custom" class="nav-link" data-original-title="" title="">
                                <i class="nav-link-icon pe-7s-gift"> </i> Mega Menu
                                <i class="fa fa-angle-down ml-2 opacity-5"></i>
                            </a>
                            <div class="rm-max-width">
                                <div class="d-none popover-custom-content">
                                    <div class="dropdown-mega-menu">
                                        <div class="grid-menu grid-menu-3col">
                                            <div class="no-gutters row">
                                                <div class="col-sm-6 col-xl-4">
                                                    <ul class="nav flex-column">
                                                        <li class="nav-item-header nav-item"> Overview</li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">
                                                                <i class="nav-link-icon lnr-inbox"></i>
                                                                <span> Contacts</span>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">
                                                                <i class="nav-link-icon lnr-book"></i>
                                                                <span> Incidents</span>
                                                                <div class="ml-auto badge badge-pill badge-danger">5</div>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">
                                                                <i class="nav-link-icon lnr-picture"></i>
                                                                <span> Companies</span>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a disabled="" href="javascript:void(0);" class="nav-link disabled">
                                                                <i class="nav-link-icon lnr-file-empty"></i>
                                                                <span> Dashboards</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-sm-6 col-xl-4">
                                                    <ul class="nav flex-column">
                                                        <li class="nav-item-header nav-item"> Favourites</li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link"> Reports Conversions </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link"> Quick Start
                                                                <div class="ml-auto badge badge-success">New</div>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">Users &amp; Groups</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">Proprieties</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="col-sm-6 col-xl-4">
                                                    <ul class="nav flex-column">
                                                        <li class="nav-item-header nav-item">Sales &amp; Marketing</li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">Queues </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">Resource Groups </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">Goal Metrics
                                                                <div class="ml-auto badge badge-warning">3</div>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">Campaigns</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="btn-group nav-item">
                            <a class="nav-link" data-toggle="dropdown" aria-expanded="false">
                                <span class="badge badge-pill badge-danger ml-0 mr-2">4</span> Settings
                                <i class="fa fa-angle-down ml-2 opacity-5"></i>
                            </a>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers dropdown-menu">
                                <div class="dropdown-menu-header">
                                    <div class="dropdown-menu-header-inner bg-secondary">
                                        <div class="menu-header-image opacity-5" style="background-image: url('assets/images/dropdown-header/abstract2.jpg');"></div>
                                        <div class="menu-header-content">
                                            <h5 class="menu-header-title">Overview</h5>
                                            <h6 class="menu-header-subtitle">Dropdown menus for everyone</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="scroll-area-xs">
                                    <div class="scrollbar-container ps">
                                        <h6 tabindex="-1" class="dropdown-header">Key Figures</h6>
                                        <button type="button" tabindex="0" class="dropdown-item">Service Calendar</button>
                                        <button type="button" tabindex="0" class="dropdown-item">Knowledge Base</button>
                                        <button type="button" tabindex="0" class="dropdown-item">Accounts</button>
                                        <div tabindex="-1" class="dropdown-divider"></div>
                                        <button type="button" tabindex="0" class="dropdown-item">Products</button>
                                        <button type="button" tabindex="0" class="dropdown-item">Rollup Queries</button>
                                    <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></div>
                                </div>
                                <ul class="nav flex-column">
                                    <li class="nav-item-divider nav-item"></li>
                                    <li class="nav-item-btn nav-item">
                                        <button class="btn-wide btn-shadow btn btn-danger btn-sm">Cancel</button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="dropdown nav-item">
                            <a aria-haspopup="true" data-toggle="dropdown" class="nav-link" aria-expanded="false">
                                <i class="nav-link-icon pe-7s-settings"></i> Projects
                                <i class="fa fa-angle-down ml-2 opacity-5"></i>
                            </a>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-rounded dropdown-menu-lg rm-pointers dropdown-menu">
                                <div class="dropdown-menu-header">
                                    <div class="dropdown-menu-header-inner bg-success">
                                        <div class="menu-header-image opacity-1" style="background-image: url('assets/images/dropdown-header/abstract3.jpg');"></div>
                                        <div class="menu-header-content text-left">
                                            <h5 class="menu-header-title">Overview</h5>
                                            <h6 class="menu-header-subtitle">Unlimited options</h6>
                                            <div class="menu-header-btn-pane">
                                                <button class="mr-2 btn btn-dark btn-sm">Settings</button>
                                                <button class="btn-icon btn-icon-only btn btn-warning btn-sm">
                                                    <i class="pe-7s-config btn-icon-wrapper"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <i class="dropdown-icon lnr-file-empty"></i>Graphic Design
                                </button>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <i class="dropdown-icon lnr-file-empty"> </i>App Development
                                </button>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <i class="dropdown-icon lnr-file-empty"> </i>Icon Design
                                </button>
                                <div tabindex="-1" class="dropdown-divider"></div>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <i class="dropdown-icon lnr-file-empty"></i>Miscellaneous
                                </button>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <i class="dropdown-icon lnr-file-empty"></i>Frontend Dev
                                </button>
                            </div>
                        </li> -->
                    </ul>        
                </div>
                <div class="app-header-right">
                    <div class="header-dots">
                        <!-- <div class="dropdown">
                            <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown" class="p-0 mr-2 btn btn-link">
                                <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <span class="icon-wrapper-bg bg-primary"></span>
                                    <i class="icon text-primary ion-android-apps"></i>
                                </span>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
                                <div class="dropdown-menu-header">
                                    <div class="dropdown-menu-header-inner bg-plum-plate">
                                        <div class="menu-header-image" style="background-image: url('assets/images/dropdown-header/abstract4.jpg');"></div>
                                        <div class="menu-header-content text-white">
                                            <h5 class="menu-header-title">Grid Dashboard</h5>
                                            <h6 class="menu-header-subtitle">Easy grid navigation inside dropdowns</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="grid-menu grid-menu-xl grid-menu-3col">
                                    <div class="no-gutters row">
                                        <div class="col-sm-6 col-xl-4">
                                            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i> Automation
                                            </button>
                                        </div>
                                        <div class="col-sm-6 col-xl-4">
                                            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                <i class="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Reports
                                            </button>
                                        </div>
                                        <div class="col-sm-6 col-xl-4">
                                            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                <i class="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Settings
                                            </button>
                                        </div>
                                        <div class="col-sm-6 col-xl-4">
                                            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                <i class="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Content
                                            </button>
                                        </div>
                                        <div class="col-sm-6 col-xl-4">
                                            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                <i class="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"></i> Activity
                                            </button>
                                        </div>
                                        <div class="col-sm-6 col-xl-4">
                                            <button class="btn-icon-vertical btn-square btn-transition btn btn-outline-link">
                                                <i class="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> </i> Contacts
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <ul class="nav flex-column">
                                    <li class="nav-item-divider nav-item"></li>
                                    <li class="nav-item-btn text-center nav-item">
                                        <button class="btn-shadow btn btn-primary btn-sm">Follow-ups</button>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="absolute bg-red-30 inset-0 z-0" @click="notifyOpen = false" v-if="notifyOpen"></div>
                        <div class="dropdown">
                            <button @click="openNotifyDiv" class="p-0 mr-2 btn btn-link no-deco"> <!--type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"-->
                                <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <span class="icon-wrapper-bg bg-danger"></span>
                                    <i class="text-sm icon text-danger  mdi mdi-notifications-active"></i>
                                    <span class="badge badge-dot badge-dot-sm badge-danger" v-if="GET_NOTIFICATION.length > 0">{{ $t('notify') }}</span>
                                </span>
                            </button>
                            <div v-if="notifyOpen" class="w-60 width-div-mobile fixed md:absolute z-50 shadow bg-white border rounded mt-10 p-3 md:p-5 right-md">
                                <div> <!--@scroll="handleScroll"-->
                                    <div class="flex flex-col">
                                        <div class="flex justify-center"> 
                                            <div class="w-36">
                                                <img class="img-fluid" src="./../assets/images/illus/notify.svg" alt="notification">
                                            </div>
                                        </div>
                                        <div class="text-center menu-header-content text-dark">
                                            <h5 class="text-sm menu-header-title">{{ $t('notify') }}</h5>
                                            <h6 class="menu-header-subtitle text-xs font-light">{{ $t('have') }} <b>{{ GET_NOTIFICATION.length }}</b> {{ $t('unread') }}</h6>
                                        </div>
                                    </div>
                                    <div v-if="GET_NOTIFICATION  !== undefined" class="overflow-y-scroll" :class="GET_NOTIFICATION.length > 0 ? 'notify-height' : ''" id="extend-height">
                                        <div class="" v-for="(notify, i) in GET_NOTIFICATION" :key="i">
                                            <NotificationHandler :notify="notify" />
                                        </div>
                                    </div>
                                    <ul class="nav flex-column" v-if="GET_NOTIFICATION.length > 0">
                                        <li class="nav-item-divider nav-item"></li>
                                        <li class="nav-item-btn text-center nav-item mt-2">
                                            <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm" @click="openNotificationSideDrawer">{{ $t('viewNotification') }}</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
                                <div id="remove-div" class="dropdown-menu-header mb-0 transition-div">
                                    <div class="dropdown-menu-header-inner"> 
                                        <div class="flex justify-center"> 
                                            <div class="w-36">
                                                <img class="img-fluid" src="./../assets/images/illus/notify.svg" alt="notification">
                                            </div>
                                        </div>
                                        <div class="menu-header-content text-dark">
                                            <h5 class="text-sm menu-header-title">{{ $t('notify') }}</h5>
                                            <h6 class="menu-header-subtitle">{{ $t('have') }} <b>{{ GET_NOTIFICATION.unread_notifications_count }}</b> {{ $t('unread') }}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-content">
                                    <div class="tab-pane active" id="tab-messages-header" role="tabpanel">
                                        <div id="extend-height" class="scroll-area-sm" :class="GET_NOTIFICATION.all_notifications.length < 2 ? 'override-height' : null">
                                            <div class="scrollbar-container ps" @scroll="handleScroll">
                                                <div class="p-3" v-if="GET_NOTIFICATION.all_notifications.length > 0">
                                                    <div class="vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                                        <div class="vertical-timeline-item vertical-timeline-element" v-for="(notify, i) in GET_NOTIFICATION.all_notifications" :key="i">
                                                            <NotificationHandler :notify="notify" />
                                                        </div>
                                                    </div>
                                                </div>
                                            <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></div>
                                        </div>
                                    </div>
                                </div>
                                <ul class="nav flex-column" v-if="GET_NOTIFICATION.all_notifications.length > 0">
                                    <li class="nav-item-divider nav-item"></li>
                                    <li class="nav-item-btn text-center nav-item">
                                        <button class="btn-shadow btn-wide btn-pill btn btn-focus btn-sm">View Notifications</button>
                                    </li>
                                </ul>
                            </div> -->
                        </div>
                        <div class="dropdown">
                            <button type="button" data-toggle="dropdown" class="p-0 mr-2 btn btn-link">
                                <span class="icon-wrapper icon-wrapper-alt rounded-circle"> 
                                    <span class="icon-wrapper-bg bg-focus"></span>
                                    <span class="language-icon opacity-8 flag small SE" v-if="GET_LOCALE_LANG === 'se'"></span>
                                    <span class="language-icon opacity-8 flag small GB" v-else></span>
                                </span>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers dropdown-menu dropdown-menu-right">
                                <div class="dropdown-menu-header">
                                    <!-- <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                                        <div class="menu-header-image opacity-05" style="background-image: url('assets/images/dropdown-header/city2.jpg');"></div>
                                        <div class="menu-header-content text-center text-white">
                                            <h6 class="menu-header-subtitle mt-0"> {{ $t('lang') }}</h6>
                                        </div>
                                    </div> -->
                                </div>
                                <!-- <h6 tabindex="-1" class="dropdown-header"> Popular Languages</h6> -->
                                <button type="button" @click="changeLocale('en')" tabindex="0" class="dropdown-item" v-if="GET_LOCALE_LANG === 'se'">
                                    <span class="mr-3 opacity-8 flag small GB"></span> English
                                </button>
                                <button type="button" @click="changeLocale('se')" tabindex="0" class="dropdown-item" v-else>
                                    <span class="mr-3 opacity-8 flag small SE"></span> Swedish
                                </button>
                                <!-- <button type="button" tabindex="0" class="dropdown-item">
                                    <span class="mr-3 opacity-8 flag large CH"></span> Switzerland
                                </button>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <span class="mr-3 opacity-8 flag large FR"></span> France
                                </button>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <span class="mr-3 opacity-8 flag large ES"></span>Spain
                                </button>
                                <div tabindex="-1" class="dropdown-divider"></div>
                                <h6 tabindex="-1" class="dropdown-header">Others</h6>
                                <button type="button" tabindex="0" class="dropdown-item active">
                                    <span class="mr-3 opacity-8 flag large DE"></span> Germany
                                </button>
                                <button type="button" tabindex="0" class="dropdown-item">
                                    <span class="mr-3 opacity-8 flag large IT"></span> Italy
                                </button> -->
                            </div>
                        </div>
                        <!-- <div class="dropdown">
                            <button type="button" aria-haspopup="true" data-toggle="dropdown" aria-expanded="false" class="p-0 btn btn-link dd-chart-btn">
                                <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <span class="icon-wrapper-bg bg-success"></span>
                                    <i class="icon text-success ion-ios-analytics"></i>
                                </span>
                            </button>
                            <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
                                <div class="dropdown-menu-header">
                                    <div class="dropdown-menu-header-inner bg-premium-dark">
                                        <div class="menu-header-image" style="background-image: url('assets/images/dropdown-header/abstract4.jpg');"></div>
                                        <div class="menu-header-content text-white">
                                            <h5 class="menu-header-title">Users Online</h5>
                                            <h6 class="menu-header-subtitle">Recent Account Activity Overview</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="widget-chart">
                                    <div class="widget-chart-content">
                                        <div class="icon-wrapper rounded-circle">
                                            <div class="icon-wrapper-bg opacity-9 bg-focus"></div>
                                            <i class="lnr-users text-white"></i>
                                        </div>
                                        <div class="widget-numbers">
                                            <span>344k</span>
                                        </div>
                                        <div class="widget-subheading pt-2">
                                            Profile views since last login
                                        </div>
                                        <div class="widget-description text-danger">
                                            <span class="pr-1"><span>176%</span></span>
                                            <i class="fa fa-arrow-left"></i>
                                        </div>
                                    </div>
                                    <div class="widget-chart-wrapper">
                                        <div id="dashboard-sparkline-carousel-3-pop"></div>
                                    </div>
                                </div>
                                <ul class="nav flex-column">
                                    <li class="nav-item-divider mt-0 nav-item"></li>
                                    <li class="nav-item-btn text-center nav-item">
                                        <button class="btn-shine btn-wide btn-pill btn btn-warning btn-sm">
                                            <i class="fa fa-cog fa-spin mr-2"></i>View Details
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                    <div class="header-btn-lg pr-0">
                    <p class="bg-gray-300 w-40 h-4 rounded mr-2 animate-pulse" v-if="loadingCompany"></p>
                    <div class="header-user-name mr-2 link-theme-color font-semibold" v-else-if="GET_USER.company">{{ GET_USER.company.name | capitalizeEachString }}</div>
                    <!-- <div class="header-user-name mr-2 link-theme-color font-semibold" v-else><span v-if="GET_ASSOCIATE_COM[0] !== undefined">{{ GET_ASSOCIATE_COM[0].company.name | capitalizeEachString }}</span></div> -->
                        <div class="widget-content p-0">
                            <div class="widget-content-wrapper">                            

                                <div class="widget-content-left">
                                    <div class="btn-group">
                                        <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn d-flex align-items-center">
                                            <p class="h-10 w-10 bg-gray-300 animate-pulse rounded-full" v-if="loadingCompany"></p>
                                            <div class="no-profile-img" :style="'background:'+rcolor" v-if="!loadingCompany">
                                                <div>
                                                    <p class="font-black" v-if="GET_USER.user !== undefined">{{ GET_USER.user.first_name + ' '+ GET_USER.user.last_name | initialNames }}</p>
                                                </div>
                                            </div>
                                            <!-- <img class="rounded-circle" :src="baseURL+'/assets/images/preview/preview.png'" alt="automatisera | preview" width="42" v-if="!loadingCompany && user.img"> -->
                                            <img class="rounded-circle" :src="$services.endpoints.LIVE_SERVER + GET_USER.img" alt="automatisera | preview" width="42" v-if="!loadingCompany && GET_USER.img">
                                            <i class="icon ion-ios-arrow-down ml-2 opacity-8"></i>
                                        </a>
                                        <div tabindex="-1" role="menu" aria-hidden="true" class="rm-pointers tweak-style dropdown-menu-lg dropdown-menu dropdown-menu-right">
                                            <!-- <div class="dropdown-menu-header">
                                                <div class="dropdown-menu-header-inner bg-info">
                                                    <div class="menu-header-image opacity-2"></div>
                                                    <div class="menu-header-content text-left">
                                                        <div class="widget-content p-0">
                                                            <div class="widget-content-wrapper">
                                                                <div class="widget-content-left mr-2">
                                           lname                         <img class="rounded-circle" :src="baseURL+'/assets/images/avatars/1.jpg'" alt="" width="42">
                                                                    <div class="cursor-pointer" @click="$store.commit('OPEN_PHOTO_MODAL')">
                                                                        <span class="change-style">Change</span>
                                                                        <div ml-3 -mt-5  class="photo-case">
                                                                            <el-tooltip effect="dark" content="Change Photo" placement="top">
                                                                                <svg class="h-6 w-6 cursor-pointer text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                                <path fill-rule="evenodd" d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                                                                                </svg>
                                                                            </el-tooltip>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="widget-content-left">
                                                                    <div class="widget-heading" v-if="user.first_name !== ''">{{ $services.helpers.capitalizeEachString(user.first_name + ' ' + user.last_name) }}</div>
                                                                    <div class="widget-heading" v-else>Alina Mcloughlin</div>
                                                                    <div class="widget-subheading opacity-8">A short profile description</div>
                                                                </div>
                                                                <div class="widget-content-right mr-2">
                                                                    <button @click="logoutUser" class="btn-pill btn-shadow btn-shine btn btn-focus">Logout</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <!-- <div class="scroll-area-xs" :class="company.length < 2 ? 'override-height' : null">
                                                <div class="scrollbar-container ps">
                                                    <ul class="nav flex-column" v-if="ownerCompany">
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link flex justify-between">
                                                                <div>{{ ownerCompany.name }} (Owner)</div>
                                                                <a href="#" class="btn-pill btn-shadow btn-shine btn btn-focus">Switch</a>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">
                                                                <i class="metismenu-icon pe-7s-add-user mr-2"></i>&nbsp;
                                                                Add Account
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">
                                                                <i class="metismenu-icon pe-7s-paper-plane mr-2"></i>&nbsp;
                                                                Invite Accountant
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="nav-link">
                                                                <i class="metismenu-icon pe-7s-power mr-2"></i>&nbsp;
                                                                Log out
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul class="nav flex-column" v-else>
                                                        <li class="nav-item" v-for="(com, i) in company" :key="i">
                                                            <a v-if="i !== 0" href="javascript:void(0);" class="nav-link flex justify-between">
                                                                <div>{{ com.company.name }}</div>
                                                                <a href="javascript:void(0);" v-if="i === 0"></a>
                                                                <a href="#" class="btn-pill btn-shadow btn-shine btn btn-focus" v-else>Switch</a>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul class="nav flex-column">
                                                <li class="nav-item-divider nav-item">
                                                </li>
                                                <li class="nav-item-btn text-center nav-item">
                                                    Version 1.2.0
                                                </li>
                                            </ul> -->
                                            <div class="scroll-area-xs override-height"><!--style="height: 150px;"-->
                                                <div class="scrollbar-container ps">
                                                    <ul class="nav flex-column">
                                                        <li class="nav-item">
                                                            <a @click="$store.commit('utility/OPEN_PHOTO_MODAL')" href="javascript:void(0);" class="text-sm font-light link-theme-color nav-link">
                                                                <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd" />
                                                                </svg>
                                                                <!-- <span class="text-xl iconly-boldFolder icon-color mr-2"></span> -->
                                                                <!-- <i class="h-5 w-5 metismenu-icon pe-7s-user mr-2"></i>&nbsp; -->
                                                                {{ $t('account') }}
                                                            </a>
                                                        </li>
                                                        <!-- <li class="nav-item">
                                                            <a href="javascript:void(0);" class="text-sm nav-link" @click="$store.commit('OPEN_INVITE_MODAL')">
                                                                <i class="metismenu-icon pe-7s-add-user mr-2"></i>&nbsp;
                                                                Add User
                                                            </a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a href="javascript:void(0);" class="text-sm nav-link">
                                                                <i class="metismenu-icon pe-7s-portfolio mr-2"></i>&nbsp;
                                                                Billing
                                                            </a>
                                                        </li> -->
                                                        <li class="font-light nav-item">
                                                            <a href="javascript:void(0);" @click="logoutUser" class="text-sm font-light link-theme-color nav-link">
                                                                <!-- <svg class="h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                                                </svg> -->
                                                                <span class="text-xl font-light icon-color mr-2 mt-1 mb-1 iconly-brokenLogout"></span>
                                                                <!-- <i class="metismenu-icon pe-7s-power mr-2"></i>&nbsp; -->
                                                                {{ $t('logout') }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <ul class="nav flex-column">
                                                <li class="nav-item-divider nav-item">
                                                </li>
                                                <li class="nav-item-btn text-center nav-item">
                                                    Version 1.2.0
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'
    import bus from './../bus'
    import NotificationHandler from './../customComponent/NotificationHandler'

    export default {
        name : 'navbar-dash',
        components : {
            NotificationHandler
        },
        data () {
            return {
                baseURL : window.location.origin,
                user : {},
                notification : [],
                ownerCompany : null,
                company : [],
                notify_count : 0,
                unread_count : 0,
                rcolor : window.localStorage.getItem('randomc') || '#000000',
                unread_notify : false,
                loadingCompany : true,
                notifyOpen : false,
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                // GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG',
                GET_NOTIFICATION : 'customer/GET_NOTIFICATION',
                // GET_OWN_COMPANY : 'customer/GET_OWN_COMPANY',
                // GET_ASSOCIATE_COM : 'customer/GET_ASSOCIATE_COM',
                GET_USER : 'customer/GET_USER'
            })
        },
        watch : {
            notifyOpen (newVal, oldVal) {
                window.Bus.$emit('show-cover', this.notifyOpen)
                // if (newVal === true) {
                // }
            }
        },
        mounted () {
            this.getAuthUser()
            this.getUserNotification()
            this.getUserRole()
        },
        methods : {
            openNotificationSideDrawer () {
                this.$store.commit('utility/OPEN_NOTIFICATION_DRAWER')
            },
            openNotifyDiv () {
                this.notifyOpen = !this.notifyOpen
            },
            getAuthUser (userID) {
                const URL = userID ? this.$services.endpoints.GET_USER + `me/${userID}/` : this.$services.endpoints.GET_USER + 'me/'
                this.$store.dispatch('customer/getAuthUser', { URL : URL })
                .then(res => {
                    this.loadingCompany = false
                }).catch(err => {
                    this.loadingCompany = false
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getAuthUser()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            getUserRole () {
                this.$store.dispatch('customer/getUserRole')
            },
            // getUserCompany (userID) {
            //     const URL = userID ? this.$services.endpoints.GET_USER_COMPANY + `${userID}/` : this.$services.endpoints.GET_USER_COMPANY
            //     // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
            //     // this.$services.axios.get(URL)
            //     this.$store.dispatch('customer/getUserCompany', { URL : URL })
            //     .then(res => {
            //         // console.log('user com:', res)
            //         this.loadingCompany = false
            //         // console.log('set')
            //         // this.ownerCompany = res.data.data
            //         this.$store.commit('customer/UPDATE_OWN_COMPANY', res.data.data)
            //         this.$store.commit('customer/SET_USER_COMPANY_BILLING', res.data.data)
            //         // if (res.data.success) {
            //         // }
            //     }).catch(err => {
            //         this.loadingCompany = false
            //         if (err.response.status === 400) {
            //             if (err.response.data.code === 401) {
            //                 this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
            //                 .then(_ => {
            //                     if (this.GET_USER_COMPANY_ID) {
            //                         this.getUserCompany(this.GET_USER_COMPANY_ID)
            //                     } else {
            //                         this.getUserCompany()
            //                     }
            //                 })
            //                 .catch(_ => {
            //                     this.$store.commit('auth/CLEAR_AUTH_USER', null)
            //                     window.Bus.$emit('sign-out')
            //                 })
            //             }
            //         }
            //     })
            // },
            getUserNotification (userID) {
                const URL = userID ? this.$services.endpoints.GET_USER_NOTIFICATION + `${userID}/` : this.$services.endpoints.GET_USER_NOTIFICATION
                // this.$services.axios.defaults.headers.common.Authorization = `Bearer ${this.USER_TOKEN}`
                // this.$services.axios.get(URL)
                this.$store.dispatch('customer/getUserNotification', { URL : URL })
                .then(res => {
                    // console.table('notification:', res)
                    // this.notification = res.data.data.all_notifications
                    // this.unread_count = res.data.data.unread_notifications_count
                    // this.notify_count = res.data.data.all_notifications_count
                    // if (res.data.success) {
                    // }
                }).catch(err => {
                    // console.log('note error:', err.response)
                    if (err.response.status === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getUserNotification()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            changeLocale (locale) {
                i18n.locale = locale
                this.$services.moment.locale(locale)
                this.$store.commit('utility/CHANGE_LOCALE', locale)
            },
            openImageModal () {
                // alert('Hey')
            },
            handleScroll (e) {
                if (parseInt(e.target.scrollTop) > 60) {
                    document.getElementById('remove-div').classList.add('hide-transition-height')
                    document.getElementById('extend-height').classList.add('notify-height')
                } else {
                    document.getElementById('remove-div').classList.remove('hide-transition-height')
                    document.getElementById('extend-height').classList.remove('notify-height')
                }
            },
            toggleSidebar () {
                // console.log('toggleing')
                document.getElementById('hamburger-btn').classList.toggle('is-active')
                document.getElementById('app-root-container').classList.toggle('sidebar-mobile-open')
            },
            toggleNavTop () {
                // console.log('clicking')
                // console.log('what:', document.getElementById('div-open'))
                document.getElementById('div-open').classList.toggle('header-mobile-open')
            },
            logoutUser () {
                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                this.$store.commit('customer/REMOVE_COMPANY_USER', null)
                this.$router.replace({ name : 'login' })
            }
        }
  }
</script>

<style lang="scss" scoped>
.app-theme-gray.fixed-header{
    .app-header__logo{
        background: #fff !important;
    }
} 
.h-5{
    height: 3rem;
}
.logo-top{
    margin-top: -0.5rem;
}
.scrollbar-container{
    .nav-item {
        .nav-link {
            .metismenu-icon{
                font-size: 2rem;
            }
        }
    }
}
.header-user-name{
    font-weight: 600;
    font-size: 1.2rem ;
}
.app-header__logo .logo-src {
    // height: 46px !important;
    // width: 99px !important;
    // background-repeat: no-repeat;
    // margin-left: 4rem;
}

.nav-item.nav-item-divider{
    margin: unset !important;
}
.dropdown-menu-header{
    margin-bottom: unset !important;
}
.nav-item.nav-item-btn{
    padding: unset !important;
}
.override-height{
    height: unset !important;
}
.skeleton-com-text{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    height: 15px;
    width: 150px;
    border-radius: 5px;
}
.skeleton-img{
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #dddddd 8%, #dddddd 18%, #dddddd 33%);
    // background-size: 800px 104px;
    // height: px;
    // width: 45px;
    // border-radius: 5px;
}
.rounded-circle-top:hover{
    background: #fff !important;
    z-index: 999;
}
.change-style{
    padding: 1px;
    border: 1px solid #fff;
}
.tweak-style{
    left: 40px !important;
}
.dropdown-menu.dropdown-menu-lg{
    min-width: 15rem !important;
}
.text-big{
    font-size: 1rem !important;
}
.nav-link{
    padding: .1rem 1rem !important;
}
.app-theme-gray .app-header{
    background: #ffff !important;
}
.ps__thumb-y{
    background :#EE4D81 !important;
}
.notify-height{
    height: 250px !important;
    transition: height 1s ease-out;
}
.hide-transition-height{
    height: 0px;
    opacity: 0;
    display: none;
}
.transition-div{
    transition: display 1s ease-out;
}
</style>