<template>
    <div class="app-sidebar sidebar-shadow sidebar-bg-custom">
        <div class="app-header__logo">
            <div class="logo-srcs"></div>
            <div class="header__pane ml-auto">
                <div>
                    <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="app-header__mobile-menu">
            <div>
                <button type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                    <span class="hamburger-box">
                        <span class="hamburger-inner"></span>
                    </span>
                </button>
            </div>
        </div>
        <div class="app-header__menu">
            <span>
                <button type="button" class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                    <span class="btn-icon-wrapper">
                        <i class="fa fa-ellipsis-v fa-w-6"></i>
                    </span>
                </button>
            </span>
        </div>    
        <div class="scrollbar-sidebar" id="top-side-to-scroll" data-cy='top-side-to-scroll'>
            <div class="app-sidebar__inner">
                <ul class="vertical-nav-menu overflow-x-hidden">
                    <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">Menu</li>
                    <!-- <li class="menu-link mm-active">
                        <router-link :to="{ name : 'dashboard' }">
                            <i class="metismenu-icon iconly-brokenCategory"></i>{{ $t('dashboard') }}
                        </router-link>
                    </li> -->
                    <li class="menu-link">
                        <router-link :to="{ name : 'appconnect' }"> <!--@click="openSubscription"-->
                            <i class="metismenu-icon iconly-brokenSetting"></i> {{ $t('appConnection') }}
                            <!-- <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i> -->
                        </router-link>
                    </li>
                    <li class="menu-link">
                        <router-link :to="{ name : 'appsettings' }"> <!--@click="openSubscription"-->
                            <i class="metismenu-icon iconly-brokenSetting"></i> {{ $t('appSettings') }}
                            <!-- <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i> -->
                        </router-link>
                    </li>
                    <!-- <li class="menu-link" v-if="GET_USER_CONNECTION.length > 0">
                        <a style="display: flex !important" class="flex justify-between align-items-center" href="javascript:void(0);" @click="openUserConnections">
                            <span class="flex justify-between align-items-center custom-left absolute" style="left: 15px"><img class="h-3 mr-3" src="./../assets/images/icons/link.png" alt="">
                                <span class="mr-1">Connections</span>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                                    </svg>
                                </span>
                            </span>
                        </a>
                        <ul id="sub_id" class="subscription-section font-light overflow-y-scroll overflow-x-hidden h-60 mm-collapse" :class="showUserConnections ? 'mm-show' : ''">
                            <li v-for="connection in GET_USER_CONNECTION" :key="connection.id">
                                <router-link :to="{ name : 'connections', params : { id : connection.uuid } }">
                                    <i class="metismenu-icon iconly-brokenBuy"></i> 
                                    {{ connection.input_endpoint.split('/').join('') }} | {{ connection.output_endpoint.split('/').join('') }} 
                                </router-link>
                            </li>
                        </ul>
                    </li> -->
                    <!-- <li class="menu-link" data-cy='add-app'>
                        <router-link :to="{ name : 'addapps' }">
                            <i class="metismenu-icon iconly-brokenPlus"></i>{{ $t('addApp') }}
                        </router-link>
                    </li> -->
                    <!-- <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('transact') }}</li> -->
                    <!-- <li class="menu-link" data-cy='billing'>
                        <router-link :to="{ name : 'billings' }">
                            <i class="metismenu-icon iconly-brokenBag-2"></i> {{ $t('billing') }}
                        </router-link>
                    </li> -->
                    <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('logs') }}</li>
                    <li class="menu-link">
                        <router-link :to="{ name : 'applogs' }">
                            <i class="metismenu-icon iconly-brokenDocument"></i> {{ $t('appLogs') }}
                        </router-link>
                    </li>
                    <!-- <li class="text-gray-300 text-xs-menu app-sidebar__heading pl-7">{{ $t('help') }}</li> -->
                    <!-- <li class="menu-link" data-cy='faq'>
                        <router-link :to="{ name : 'faq' }">
                            <i class="metismenu-icon iconly-brokenChat"></i> FAQ
                        </router-link>
                    </li>
                    <li class="menu-link">
                        <a href="javascript:void(0);" @click="$store.commit('utility/OPEN_INVITE_MODAL')">
                            <i class="metismenu-icon iconly-brokenAdd-User"></i> {{ $t('inviteUsers') }}
                        </a>
                    </li> -->
                    <!-- <li class="menu-link" v-if="GET_USER_UTIL === 'true'">
                        <a :href="baseURL+'/accountant'">
                            <i class="metismenu-icon iconly-brokenLogout"></i> {{ $t('exitUser') }}
                        </a>
                    </li>
                    <li class="menu-link" v-if="GET_IN_HOUSE_UTIL === 'true'">
                        <a :href="baseURL+'/regular'">
                            <i class="metismenu-icon iconly-brokenLogout"></i> {{ $t('exitUser') }}
                        </a>
                    </li> -->
                    <!-- <li class="menu-link">
                        <a href="javascript:void(0);" @click="openCreateModal">
                            <i class="metismenu-icon iconly-brokenLogout"></i> {{ $t('createRole') }}
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'sidebar-dash',
        data () {
            return {
                baseURL : window.location.origin,
                userSubscription : [],
                userObject : null,
                showSubscription : false,
                showUserConnections : false,
            }
        },
        watch : {
            $route (to, from) {
                // console.log('to::', to.path)
                // if (to.name !== from.name) {
                //     this.showUserConnections = false
                //     document.getElementById('sub_id').classList.remove('mm-show')
                //     document.getElementById('top-side-to-scroll').classList.remove('overflow-y-scroll')
                // }
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_SUBSCRIPTION : 'customer/GET_SUBSCRIPTION',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
                // GET_USER_UTIL : 'accountant/GET_USER_UTIL',
                // GET_IN_HOUSE_UTIL : 'house/GET_IN_HOUSE_UTIL',
                GET_USER : 'customer/GET_USER',
                GET_USER_CONNECTION : 'customer/GET_USER_CONNECTION',
            })
        },
        mounted () {
            // this.getAllConnections()
        },
        methods : {
            openCreateModal () {
                window.Bus.$emit('open-create-role')
            },
            getAllConnections () {
                this.$store.dispatch('customer/getUserConnections')
                .then(r => console.log('r:', r))
                .catch(err => {
                    if (err.response.data.code === 401) {
                        this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                        .then(_ => {
                            this.getAllConnections()
                        })
                        .catch(_ => {
                            this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            window.Bus.$emit('sign-out')
                        })
                    }
                })
            },
            getAuthUser () {
                this.$store.dispatch('customer/getAuthUser', { URL : this.$services.endpoints.GET_USER })
                .then(res => {
                    this.loadingCompany = false
                }).catch(err => {
                    this.loadingCompany = false
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getAuthUser()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
            openUserConnections () {
                this.showUserConnections = !this.showUserConnections
                if (this.showUserConnections) {
                    document.getElementById('sub_id').classList.add('mm-show')
                    // document.getElementById('sub_id').classList.add('override-height')
                    // document.getElementById('top-side-to-scroll').classList.add('overflow-y-scroll')
                } else {
                    document.getElementById('sub_id').classList.remove('mm-show')
                    // document.getElementById('top-side-to-scroll').classList.remove('overflow-y-scroll')
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

.app-sidebar .app-sidebar__inner{
    padding: unset !important;
}

.sidebar-bg-custom{
    background: $sidebar-bg-color;
    border-right: 1px solid #344258;
}
.app-sidebar__heading{
    // color: #ffffff !important;
    // font-size: 0.6rem;
}
.vertical-nav-menu li.mm-active > a {
    color: #bfc3ce !important;
    font-size: 0.8rem;
    font-weight: unset !important;
}
.vertical-nav-menu li a{
    color: #bfc3ce !important;
    font-size: 0.8rem;
}
.vertical-nav-menu li.menu-link:hover{
    border-left-color: $theme-primary-color !important;
    border-left-width: 2px;
    background: $menu-hover !important;
}
.vertical-nav-menu ul > li > a {
    color: #bfc3ce !important;
    font-size: 0.8rem;
    transition: all .3s ease;
}
.vertical-nav-menu li a:hover:not(.router-link-exact-active) {
    background: unset !important;
    text-decoration: none;
    margin-left: 13px;
    i.metismenu-state-icon{
        margin-left: 10px;
        position: sticky;
    }
}
.vertical-nav-menu i.metismenu-icon{
    opacity: unset !important;
}
.vertical-nav-menu ul {
    transition: padding 300ms;
    padding: .5em 0 0 0.6rem !important;
}
.router-link-exact-active {
    // border-left-color: $theme-primary-color !important;
    border-left-width: 2px;
    background: $menu-hover !important;
    // left: 11px;
}
// .subscription-section li .router-link-exact-active{
//     color: #bfc3ce !important;
//     font-size: 0.8rem;
//     font-weight: bold;
// }
</style>