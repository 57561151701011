<template>
    <div class="flex flex-col">
        <div  class="app-container bg-gray-100 body-tabs-shadow app-theme-gray fixed-header fixed-sidebar" id="app-root-container">
            <NavbarDash />
            <div id="main-holder" class="app-main single-main">
                <SidebarDash />
                <div class="app-main__outer">
                    <router-view />
                    <FooterDash />
                </div>
            </div>
            <Notification />
            <el-dialog
            :visible.sync="modal_invite"
            width="60%"
            :handleClose="handleClose"
            center>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 -mt-10">
                        <img src="./../../assets/images/illus/mail.svg" class="img-fluid d-none d-lg-block" alt="mail">
                        <h2 class="text-2xl text-gray-900 font-semibold">{{ $t('inviteUsers') }}</h2>
                        <!-- <span>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eligendi asperiores adipisci corporis sit repellat in aperiam quia odio doloribus dolore.</span> -->
                    </div>
                    <div class="col-md-6">
                        <form class="flex flex-col space-y-3" @submit.prevent="inviteUser">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="firstname">{{ $t('firstName') }}</label>
                                        <input type="text" v-model="firstname" class="form-control font-light text-xs w-full pr-1" v-bind:placeholder="$t('firstName')" required>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="firstname">{{ $t('lastName') }}</label>
                                        <input type="text" v-model="lastname" class="form-control font-light text-xs w-full pl-1" v-bind:placeholder="$t('lastName')" required>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="email">{{ $t('email') }}</label>
                                        <input type="email" v-model="email" class="form-control font-light text-xs w-full pr-1" v-bind:placeholder="$t('email')" required>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="role">{{ $t('userRole') }}</label>
                                        <select class="form-control w-full font-light text-xs" v-model="userRoleId" required>
                                            <option selected disabled>{{ $t('userRole') }}</option>
                                            <option :value="role.id" v-for="role in GET_USER_ROLE" :key="role.id">{{ role.role }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn hover:text-white text-base btn-custom" :disabled="creating">
                                        <span v-if="creating">{{ $t('creating') }}</span>
                                        <span v-else>{{ $t('create') }}</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </el-dialog>

            <ModalHandler v-if="openCreateRole" width='w-1/3'  :title="$t('createRole')"  @handleClose="openCreateRole = false" :close="!true">
                <div class="container" slot="body">
                    <div class="row">
                        <div class="col-md-12">
                            <form @submit.prevent="createUserRole">
                                <!-- <div class="form-group">
                                    <label for="account">Role name</label><br>
                                    <input type="text" v-model="roleName" class="form-control font-light text-xs w-full pr-1" required>
                                </div> -->
                                <div class="form-group">
                                    <label for="account">{{ $t('selectRole') }}</label><br>
                                    <select v-model="roleName" class="form-control">
                                        <option value="SYSTEMADMIN">SYSTEMADMIN</option>
                                        <option value="ADMIN">ADMIN</option>
                                        <option value="GUEST">GUEST</option>
                                        <option value="USER">USER</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <button :disabled='creating' type="submit" class="btn btn-custom hover:text-white">
                                        <span v-if="creating">{{ $t('adding') }}</span>
                                        <span>{{ $t('add') }}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div slot="footer" class="flex space-x-5">
                    
                </div>
            </ModalHandler>

            <ModalHandler v-if="openAppConnectionProgress" width='w-1/3'  title="App Connection"  @handleClose="openAppConnectionProgress = false" :backdropOff='true' :close="true">
                <div class="container" slot="body">
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <div class="flex flex-col space-y-2 w-3/4 mb-4">
                                <div v-for="item in GET_APP_CONNECTION" :key="item.unique" class="flex justify-between align-items-center space-x-5">
                                    <div>{{ item.connectionName }}</div>
                                    <div class="flex align-items-center space-x-3">
                                        <span v-if="!item.complete && !item.failed">
                                            <svg class="animate-spin mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                        </span>
                                        <span v-if="item.complete && !item.failed">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                        <span v-if="!item.complete && item.failed">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="GET_COMPLETE_SUBMISSION" class="flex justify-end mt-5">
                                <button @click="closeProgressModal" class="flex justify-between text-center bg-green-500 text-white px-3 py-2 rounded ">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div slot="footer" class="flex space-x-5">
                    
                </div>
            </ModalHandler>

            <ModalHandler v-if="openSelectSubscription" width='w-1/4'  :title="$t('subscription')"  @handleClose="openSelectSubscription = false" :backdropOff='true' :close="true">
                <div class="container" slot="body">
                    <div class="row">
                        <div class="col-lg-12 mt-3">
                            <form class="mt-3" @submit.prevent="choosenSubscription">
                                <div class="flex mt-2 mb-2">
                                    <h3>{{ $t('selectSubscription') }}</h3>
                                </div>
                                <div class="form-group">
                                    <select v-model="userSubscription" class="flex justify-between align-items-center space-x-4 focus:outline-none border px-3 bg-white py-2 rounded-md text-gray-500 w-full" required>
                                        <option :value="index" v-for="(subscription, index) in GET_USER_SUBSCRIPTION" :key="subscription.id">{{ subscription.billing_period_unit | capitalizeNames }} {{' (SEK '+ subscription.plan_amount + ')'}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <button class="c-btn btn-next btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900 w-full">Choose</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div slot="footer" class="flex space-x-5">
                    
                </div>
            </ModalHandler>

            <!-- Notification drawer -->
            <el-drawer
                :title="$t('notify')"
                :visible.sync="notificationDrawer"
                size="27%"
                custom-class="notifiy-drawer-style"
                :direction="direction">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 flex-none">
                            <div class="form-group">
                                <input type="text" class="form-control font-light text-xs w-full pl-1" v-bind:placeholder="$t('search')" required>
                            </div>
                        </div>
                        <div class="col-md-12 flex-1 h-screen overflow-y-scroll">
                            <div class="flex flex-col" v-for="(notify, i) in GET_NOTIFICATION.all_notifications" :key="i">
                                <NotificationHandler :notify="notify" :archive="true" />
                            </div>
                        </div>
                    </div>
                </div>
            </el-drawer>
            
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { v4 as uuidv4 } from 'uuid'
    // import KProgress from 'k-progress'
    import bus from './../../bus'
    import NavbarDash from '@/components/Navbar'
    import SidebarDash from '@/components/Sidebar'
    import FooterDash from '@/components/Footer'
    import ModalContainer from '@/customComponent/DownloadModal'
    import ModalHandler from '@/customComponent/Modal'
    import NotificationHandler from '@/customComponent/NotificationHandler'


    export default {
        name : 'main-root-dash',
        components : {
            NavbarDash,
            SidebarDash,
            ModalContainer,
            ModalHandler,
            // 'k-progress' : KProgress,
            NotificationHandler,
            FooterDash,
            Notification : () => import('./../../customComponent/NotificationAlert')
        },
        data () {
            return {
                baseURL : window.location.origin,
                openCreateRole : false,
                openPermission : false,
                openAppConnectionProgress : false,
                openSelectSubscription : false,
                userSubscription : '',
                roleName : '',
                permissionName : '',
                role : 'Select role',
                userRoleId : '',
                firstname : '',
                lastname : '',
                email : '',
                creating : false,
                drawer : false,
                direction: 'rtl',
            }
        },
        watch : {
            
        },
        computed : {
            notificationDrawer : {
                get : function () {
                    return this.GET_NOTIFICATION_DRAWER
                },
                set : function () {
                    return this.$store.commit('utility/OPEN_NOTIFICATION_DRAWER')
                }
            },
            modal_invite : {
                get : function () {
                    return this.MODAL_INVITE
                },
                set : function () {
                    return this.$store.commit('utility/OPEN_INVITE_MODAL')
                }
            },
            modal_photo : {
                get : function () {
                    return this.MODAL_PHOTO
                },
                set : function () {
                    return this.$store.commit('utility/OPEN_PHOTO_MODAL')
                }
            },
            modal_util : {
                get : function () {
                    return this.MODAL_UTIL
                },
                set : function () {
                    return this.$store.commit('utility/OPEN_MODAL_UTIL')
                }
            },
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_ROLE : 'customer/GET_USER_ROLE',
                GET_USER : 'customer/GET_USER',

                MODAL_INVITE : 'utility/MODAL_INVITE',
                MODAL_PHOTO : 'utility/MODAL_PHOTO',
                MODAL_UTIL : 'utility/MODAL_UTIL',

                GET_NOTIFICATION_DRAWER : 'utility/GET_NOTIFICATION_DRAWER',
                GET_NOTIFICATION : 'customer/GET_NOTIFICATION',
                GET_APP_CONNECTION : 'customer/GET_APP_CONNECTION',
                GET_USER_SUBSCRIPTION : 'customer/GET_USER_SUBSCRIPTION',
                GET_COMPLETE_SUBMISSION : 'customer/GET_COMPLETE_SUBMISSION'
            })
        },
        mounted () {
            document.addEventListener('contextmenu', function (e) {
                e.preventDefault()
            })
            
            window.Bus.$on('open-create-role', _ => {
                this.openCreateRole = true
            })

            window.Bus.$on('open-connection-progress', _ => {
                this.openAppConnectionProgress = true
            })

            bus.$on('select-subscription', _ => {
                console.log('it working here:', this.openSelectSubscription)
                this.openSelectSubscription = true
            })

            window.Bus.$on('sign-out', _ => {
                this.$store.commit('customer/REMOVE_COMPANY_USER', null)
                if (this.$route.path !== '/login') {
                    this.$router.replace({ name : 'login' })
                }
            })
            window.Bus.$on('show-cover', e => {
                if (e === true) {
                    this.showFullCover = true
                } else {
                    this.showFullCover = false
                }
            })

            const self = this
            window.Bus.$on('open-notification-drawer', e => {
                // self.openDrawer()
                // this.drawer = true
                self.$store.commit('utility/OPEN_MODAL_UTIL')
            })
        },
        methods : {
            createUserRole () {
                if (this.roleName === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else {
                    this.creating = true
                    const payload = {
                        role : this.roleName,
                    }
                    this.$store.dispatch('customer/createUserRole', payload)
                    .then(_ => {
                        this.creating = false
                        this.roleName = ''
                        this.$services.helpers.notification(this.$t('save'), 'success', this)
                    })
                    .catch(err => {
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.createUserRole()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                        if (err.response.status === 400) {
                            this.creating = false
                            this.$services.helpers.notification(err.response.data.message, 'error', this)
                        }
                    })
                }
            },
            closeCartDiv () {
                window.Bus.$emit('close-cart')
                this.showFullCover = false
            },
            handleClose () {
                this.$store.commit('utility/OPEN_INVITE_MODAL')
            },
            inviteUser () {
                if (this.firstname === '' || this.lastname === '' || this.email === '') {
                    this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
                } else if (!this.$services.helpers.validateEmailAddress(this.email)) {
                    this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
                } else if (this.userRoleId === '' || !parseInt(this.userRoleId)) {
                    this.$services.helpers.notification(this.$t('userRole'), 'error', this)
                } else {
                    this.creating = true
                    const payload = {
                        email : this.email,
                        first_name : this.firstname,
                        last_name : this.lastname,
                        company_id : this.GET_USER.company.id,
                        role_id : parseInt(this.userRoleId)
                    }
                    this.$store.dispatch('customer/companyInviteUser', payload)
                    .then(res => {
                        this.creating = false
                        if (res.data.success) {
                            this.clearInviteField()
                            this.$services.helpers.notification(this.$t('invitedSuccessful'), 'success', this)
                        }
                    }).catch((err) => {
                        this.creating = false
                        if (err.response.status === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.inviteUser()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                            })
                        }
                        if (err.response.status === 400) {
                            this.$services.helpers.notification(err.response.data.message, 'error', this)
                        }
                    })
                }
            },
            choosenSubscription () {
                if (this.userSubscription !== "") {
                    bus.$emit('choosen-subscription', this.userSubscription)
                    this.openSelectSubscription = false
                }
            },
            clearInviteField () {
                this.firstname = ''
                this.lastname = ''
                this.email = ''
                this.userRoleId = ''
            },
            closeProgressModal () {
                this.$store.commit('customer/PROGRESS_MODAL_CLOSE')
                this.openAppConnectionProgress = false  
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.form-select{
    padding-top: unset !important;
}
.form-control{
    height: unset !important;
    padding: .275rem .75rem !important;
}
.app-theme-gray.app-container{
    background: #f1f4f6 !important
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
.form-control, .form-select{
    border: 1px solid $form-border !important;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    // border: 1px solid #ccc;
    color: white;
    border-radius: 5px;
    background-color: $theme-primary-color;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
 @media only screen and (max-width: 992px) {
    .system-maintain-top{
        top:120px !important;
    }
    .system-main {
        padding-top: 200px !important;
    }
}

@media only screen and (min-width: 993px) {
    .system-maintain-top{
        top:54px !important;
    }
    .system-main {
        padding-top: 120px !important;
    }
}
</style>