// Adding localization to vue app
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { se, en } from './ValidateMessages'

Vue.use(VueI18n)

const messages = {
    en: {
        fields: {
            username : 'Username',
            email: 'E-mail Address',
            emailUsername : 'Email Address / Username',
            password: 'Password',
            firstName : 'Firstname',
            lastName : 'Lastname',
            companyNumber : 'Company Number',
            companyEmail : 'Company Email',
            companyName : 'Company Name',
        },
        validation: en.messages,
        username : 'Username',
        dashboard : 'Dashboard',
        addApp : 'Add Apps',
        inviteUsers : 'Invite Users',
        exitUser : 'Exit User View',
        billing : 'Billing',
        appLogs : 'App Logs',
        logs : 'Logs',
        transactions : 'Transactions',
        warning : 'Warning',
        removeSuccessful : 'removed successfully',
        help : 'Help',
        loginHeader : 'Login to Client Portal',
        forgetPassword : 'Forgot Password',
        notCustomer : 'Not a Customer',
        welcome : 'Welcome to Sign Up',
        golf : 'Client Portal for GOLF clients',
        reset : 'Reset Password',
        resetYourPassword : 'Reset your password',
        resetting : 'Resetting...',
        successReset : 'Password reset successfully',
        sendLink : 'Sending link',
        already : 'Already a Customer',
        signup : 'Sign Up',
        creatingAccount : 'Creating Account...',
        createNewAccount : 'Create New Account',
        login : 'Login here',
        headNotAvailable : 'Looks like you\'re lost',
        pageNotFound : 'The page you are looking for is not available!',
        previous : 'Go to previous page',
        settingPrevious : 'Previous',
        settings : 'App Settings',
        FAQHead : 'Common Questions',
        FAQSmall : 'Frequently asked questions',
        noCategory : 'No FAQ category lists',
        searchCategory : 'Search Categories',
        searchApp : 'Search Apps',
        filterApp : 'Accounting type',
        type : 'Type',
        systemLog : 'System Logs',
        auditLog : 'All Audit Logs',
        date : 'Date',
        apps : 'Apps',
        detail : 'Details',
        user : 'Users',
        level : 'Level',
        all : 'All',
        filterBy : 'Filter by',
        filterUser : 'Users',
        paymentType : 'Current Payment Type',
        update : 'Update',
        updating : 'Updating...',
        switchInvoice : 'Switch to Invoice Billing',
        billingHistory : 'Billing History',
        save : 'Save',
        cancel : 'Cancel',
        yes : 'Yes',
        no : 'No',
        create : 'Create',
        creditCard : 'Credit Card',
        invoicing : 'Invoicing',
        payWithCard : 'Pay with Card',
        noDataTable : 'No data available in table',
        logout : 'Logout',
        account : 'Account',
        accountConfirm : 'Account confirm successfully',
        userRole : 'Select User Role',
        loginSuccess : 'Account login successfully',
        fieldRequired : 'All fields are required',
        passwordGreater : 'Password should be greater than 6',
        passwordMatch : 'Password do not match',
        invalidToken : 'Invalid token',
        daysBack : 'We offer 7 days of retroactive sync. If you wish to buy for retroactive accounting further back in time, you are welcome to contact support at info@automatiseramera.se',
        switchYearlySuccess : 'Thank you, an invoice will be emailed to the address provided shortly',
        invalidCompany : 'Invalid Company Number',
        accountCreated : 'Account created successfully',
        readInfo : 'Read Information',
        configure : 'Configure and Save Settings',
        activate : 'Activate App',
        activating : 'Activating',
        deactivate : 'Deactivate',
        deactivating : 'Deactivating',
        status : 'Status',
        config : 'Configure',
        transactionHistory : 'Transaction History',
        activateApp : 'Activate Current App',
        activateNow : 'Activate',
        updateInfo : 'Update Information',
        upload : 'Upload Photo',
        fileUpload : 'Upload File',
        connecting : 'Connecting',
        companies : 'Companies',
        firm : 'Accounting Firm',
        language : 'Choose Language',
        companyName : 'Company Name',
        companyNumber : 'Company Number',
        AccountingFirmName : 'Accounting Firm Name',
        firmName : 'Firm Name',
        firmNumber : 'Accounting Firm Number',
        notify : 'Notification',
        have : 'You have',
        unread : 'unread messages',
        switch : 'Switch to',
        english : 'English',
        bookKept : 'Total Transaction Bookkept',
        totalSaving : 'Total Savings in Accounting cost',
        subscription : 'Subscriptions',
        dueDate : 'Due Date',
        file : 'File',
        zipCode : 'Zip Code',
        city : 'City',
        email : 'Email',
        invoiceEmail : 'Invoice Email',
        billingAddress : 'Billing Address',
        role : 'Role',
        management : 'Management',
        dateAdded : 'Date Added',
        phoneNumber : 'Phone Number',
        accountant : 'Accountant',
        name : 'Name',
        totalBookKept : 'Total Transaction Bookkept',
        totalSubscription : 'Total Subscriptions',
        mostSold : 'Most Sold Apps',
        totalPartners : 'Total Partners',
        amountDue : 'Amount Due',
        imageUploadSuccessful : 'Image uploaded successfully',
        createNote : 'Creating note...',
        noteCreated : 'Note created',
        emptyNote : 'Note field cannot be empty',
        updateNote : 'Updating note...',
        noteUpdated : 'Note updated',
        pleaseWait : 'Please wait...',
        successCompanyUpdated : 'Company information updated successful',
        requireCompanyNumber : 'Company name/Company number is required',
        requireCompanyAddress : 'Company address is required',
        requirePhonenumber : 'Phone Number is required',
        requireZipCode : 'Company zip code is required',
        requireCity : 'Company city is required',
        updateApp : 'Updated Apps successfully',
        redirectYou : 'Please wait while we direct you.',
        confirmInvite : 'You have confirmed the invitation',
        hi : 'Hi',
        almostDone : 'You\'re almost done',
        massReassignAccountant : 'Mass Reassign Accountant',
        crop : 'Crop',
        cropping : 'Cropping',
        finishedUpload : 'Finished & Upload',
        uploading : 'Uploading',
        processing : 'processing',
        firstName : 'Firstname',
        lastName : 'Lastname',
        other : 'Other',
        creating : 'Creating...',
        accountantRemove : 'Accountant remove successfully',
        action : 'Action',
        accountNumberRequired : 'Account Number required',
        createdCustom : 'Account Number mapping created successfully',
        deletedCustom : 'Account Number mapping deleted successfully',
        activatedSubscription : 'Subscription activated successfully',
        deactivatedSubscription : 'Subscription deactivated successfully',
        createdDeliverySubscription : 'Delivery settings added',
        deletedDeliverySubscription : 'Delivery settings deleted successfully',
        fileRequired : 'File field required',
        swishFileSuccess : 'File uploaded successfully',
        invalidFile : 'Invalid file format',
        assign : 'Assign',
        accountantCompanies : 'Accounting Firms',
        reassignAccountant : 'Re-Assign Accountant',
        assignTo : 'Assign To',
        assigning : 'Assigning',
        continue : 'Continue',
        prev : 'Previous Page',
        next : 'Next',
        uploadFinished : 'Finished & Uploaded',
        invalidAccountID : 'Invalid account ID',
        selectAssignee : 'Please select assignee',
        selectCompany : 'Please select at least a company',
        selectCompanies : 'Select companies',
        successAssign : 'Assigned Successfully',
        visma : 'Visma credential was added successfully',
        invalidSession : 'Invalid Session from User. Redirecting...',
        subscriptionPage : 'Subscription Page',
        switchCard : 'Your Current Payment Type has been switch to Card',
        redirectWait : 'Please wait whiles we redirect you',
        setUpAccount : 'Set Up Account',
        emailAddress : 'Email Address',
        emailUsername : 'Email Address / Username',
        password : 'Password',
        confirmPassword : 'Confirm Password',
        newPassword : 'New Password',
        confirmNewPassword : 'Confirm New Password',
        createdPayTermSubscription : 'Payment Term Subscription created successfully',
        deletedPayTermSubscription : 'Payment Term Subscription deleted successfully',
        accountNumber : 'Account Number',
        addAccountNumber : 'Add Account Number',
        adding : 'Adding',
        enterNumber : 'Enter Account Number',
        invalidNumber : 'Account Numbers should be Numbers Only',
        accountAddedSuccess : 'Account Number added successfully',
        // companies : 'Companies',
        search : 'Search',
        chooseFile : 'Choose a file',
        addFAQ : 'Add FAQ',
        addFrequent : 'Add a frequently asked question',
        question : 'Question',
        category: 'Category',
        addCategory : 'Add Category',
        answer : 'Answer',
        dashboardViewOnly : 'Dashboard view only',
        add : 'Add',
        awesome : 'Write something awesome', // Write something awesome
        addNote : 'addNote',
        paymentInterval : 'Payment Interval',
        monthly : 'Monthly',
        switchYear : 'Switch to Yearly',
        subscriptionMonth : 'Subscriptions created this month',
        faqAdded : 'FAQ added successfully',
        switchSuccess : 'Thank you, the company is now set up as an invoice customer',
        totalAccountant : 'Total Accountants',
        connectedCustomer : 'Total Connected Customers',
        // totalSubscription : 'Total Subscriptions',
        transactionBookkept : 'Total Transaction Bookkept',
        totalKickback : 'Total Kickbacks/Discount',
        subscriptionType : 'Subscription Type',
        saveMoney : 'Total money save in time',
        totalHours : 'Total work hours saved',
        appCatalogue : 'App Catalogue',
        overview : 'Overview',
        categorySelect : 'Please select a category',
        chooseCategory : 'Choose Category',
        genericQuestion : 'Generic Question',
        testOrder : 'Test One Order Only',
        testSend : 'Run test',
        notFoundText : 'Oh no! Page Not Found',
        backHome : 'Back to Homepage',
        optionalSetting : 'Optional Settings',
        paymentMappingSetting : 'Payment Mapping',
        accountSetting : 'Account Mapping',
        bambooHRSetting : 'BambooHR Mapping',
        deliverySetting : 'Delivery Mapping',
        more : 'more',
        less : 'less',
        at : 'at',
        markRead : 'Mark as read',
        testing : 'Testing',
        bambooCreated : 'BambooHR created successfully',
        bambooDeleted : 'Data deleted successfully',
        connectShopify : 'Anslut med shopify',
        connectStripe : 'Anslut med Stripe',
        buyNow : 'Buy Now',
        price : 'Price',
        notifyRedirect : 'Link for more info',
        fieldValue : 'Field value cannot be longer than 50 Characters',
        invalidEmail : 'Invalid Email Address',
        invitedSuccessful : 'Invitation sent to user',
        paid : 'Paid',
        unPaid : 'Unpaid',
        processingFile : 'File is being processed, please hold',
        fileResponseSuccess : 'Following vouchers were successfully posted',
        fileResponseError : 'The upload failed, see below error message from the accounting app',
        viewNotification : 'View Notifications',
        createRole : 'Create Role',
        selectRole : 'Select Role',
        yourAppConnection : 'Your App Connections',
        removeConnection : 'Are you sure you want to remove connection',
        selectApps : 'Select Apps',
        selectSubscription : 'Select Subscription',
        selectRelevantApps : 'Select the Relevant Objects which are to be synched between the apps',
        initializeConnection : 'Initialize connections',
        authenticateApps : 'Authenticate both Applications',
        connect : 'Connect',
        connected : 'Connected',
        selectAndMapFields : 'Select & Map the fields which are relevant to  parse in each object',
        setRulesForSync : 'Set Rules for behaviours of the sync',
        connectApps : 'Connect Apps',
        mapObjects : 'Map Objects',
        mapFields : 'Map Fields',
        setRules : 'Set Rules',
        appConnection : 'App Connection',
        appSettings : 'App Settings',
        connectionObject : 'Connection Object',
        mapFilters : 'Map Filters',
        filters : 'Filters',
        actions : 'Actions',
        intervals : 'Intervals',
        finished : 'Notification',
        exit : 'finish',
        syncUrl : 'Webhook',
        selectEndpoint : 'Select Object',
        selectFilters : 'Select Filters',
        selectKeys : 'Select keys',
        notifyFinishRule : 'Notify',
        addValue : 'Add value',
        selectActionType : 'Select action type',
        selectNameType : 'Select name type',
        selectKeySource : 'Select key source',
        selectKeyDestination : 'Select key destination',
        source : 'Source',
        destination : 'Destination',
        selectOutput : 'Select output',
        selectOutputSource : 'Select output source',
        selectOutputDestination : 'Select output destination',
        trigger : 'Trigger', 
        triggerStatus : 'Trigger status',
        triggerForEvery : 'Every',
        triggerPeriod : 'Period',
        selectStatusType : 'Select status type',
        notificationChannel : 'Channel',
        notificationReceiver : 'Receiver',
        syncWithEndpoint : 'Sync with endpoint',
        syncSuccessOrFailure : 'on Success/Failure',
        seconds : 'Seconds',
        mnutes : 'Minutes',
        hours : 'Hours',
        days : 'Days',
        complete : 'Complete',
        submit : 'Submit',
        filterExistence : 'Filter already exist for same connection',
        error : 'Error',
        info : 'Info',
        testApp : 'Test App',

    },
    se: {
        fields: {
            username : 'Användarnamn',
            email : 'E-post',
            emailUsername : 'E-postadress / Användarnamn',
            password : 'Lösenord',
            firstName : 'Förnamn',
            lastName : 'Efternamn',
            companyNumber : 'Orgnummer',
            companyEmail : 'Företag E-post',
            companyName : 'Företag',
        },
        validation: se.messages,
        username : 'Användarnamn',
        dashboard : 'Lobby',
        addApp : 'Lägg till app',
        inviteUsers : 'Bjud in användare',
        exitUser : 'Avsluta användarvyn',
        appLogs : 'Apploggar',
        billing : 'Betalningar',
        logs : 'Loggar',
        transactions : 'Fakturering',
        warning : 'Varning',
        removeSuccessful : 'Borttagen',
        help : 'Hjälp',
        loginHeader : 'Logga in',
        forgetPassword : 'Glömt ditt lösenord',
        notCustomer : 'Inte en kund ännu',
        welcome : 'Skapa konto',
        golf : 'Klientportal för GOLF-kunder',
        reset : 'Återställ lösenord',
        resetYourPassword : 'Återställ ditt lösenord',
        resetting : 'Återställ',
        successReset : 'Lösenordet har återställts',
        sendLink : 'Skickar länk',
        already : 'Redan kund',
        signup : 'Skapa konto',
        creatingAccount : 'Skapar konto...',
        createNewAccount : 'Skapa nytt Konto',
        login : 'Logga in',
        headNotAvailable : 'Hoppsan, sidan finns inte',
        pageNotFound : 'Hoppsan, sidan finns inte',
        previous : 'Föregående sida',
        settingPrevious : 'Föregående',
        settings : 'App Inställningar',
        FAQHead : 'Vanliga frågor',
        FAQSmall : 'Vanligt ställda frågor',
        noCategory : 'Ingen kategorilista',
        searchCategory : 'Sökkategorier',
        searchApp : 'Sök appar',
        filterApp : 'Redovisningstyp',
        type : 'Typ',
        systemLog : 'Systemloggar',
        auditLog : 'Alla loggar',
        date : 'Datum',
        apps : 'Appar',
        detail : 'Detaljer',
        user : 'Användare',
        level : 'Meddelandetyp',
        all : 'Allt',
        filterBy : 'Filtrera efter',
        filterUser : 'Användare',
        paymentType : 'Aktuell betalningstyp',
        update : 'Uppdatera',
        updating : 'Uppdaterar...',
        switchInvoice : 'Byt till fakturabetalning',
        billingHistory : 'Faktureringshistorik',
        save : 'Spara',
        cancel : 'Avbryt',
        yes : 'Ja',
        no : 'Nej',
        create : 'Skicka',
        creditCard : 'Kreditkort',
        invoicing : 'Faktura',
        payWithCard : 'Betala med kort',
        noDataTable : 'Ingen data tillgänglig i tabellen',
        logout : 'Logga ut',
        account : 'Konto',
        accountConfirm : 'Inställningar sparade',
        userRole : 'Välj användarroll',
        loginSuccess : 'Kontoinloggning lyckades',
        fieldRequired : 'Alla fält är obligatoriska',
        passwordGreater : 'Lösenordet bör vara större än 6',
        passwordMatch : 'Lösenordet matchar inte',
        invalidToken : 'Ogiltigt token',
        daysBack : 'Vi bjuder på 7 dagars retroaktiv synk. Om ni önskar köpa till retroaktiv bokföring längre bak i tiden är ni välkomna att kontakt supporten på info@automatiseramera.se',
        switchYearlySuccess : 'Tack, en faktura kommer mejlas ut inom kort',
        invalidCompany : 'Ogiltigt företagsnummer',
        accountCreated : 'Kontot har skapats',
        readInfo : 'Informationsguide',
        configure : 'Konfigurera och spara inställningar',
        activate : 'Aktivera app',
        activating : 'Aktiverar',
        deactivate : 'Avaktivera',
        deactivating : 'Inaktiverar',
        status : 'Status',
        config : 'Konfigurera',
        transactionHistory : 'Transaktionshistorik',
        activateApp : 'Aktivera aktuell app',
        activateNow : 'Aktivera',
        updateInfo : 'Uppdatera',
        upload : 'Ladda upp foto',
        fileUpload : 'Skicka',
        connecting : 'Ansluter',
        companies : 'Företag',
        firm : 'Byråer',
        language : 'Välj Språk',
        companyName : 'Företag',
        companyNumber : 'Orgnummer',
        AccountingFirmName : 'Byrå',
        firmName : 'Byrå',
        firmNumber : 'Orgnummer',
        notify : 'Meddelande',
        have : 'Du har',
        unread : 'Olästa meddelanden',
        switch : '  Växla till',
        english : 'Engelska',
        bookKept : 'Totalt bokförda transaktioner',
        totalSaving : 'Totala besparingar i redovisningskostnader',
        subscription : 'Prenumerationer',
        dueDate : 'Förfallodatum',
        file : 'Filer',
        zipCode : 'Postnummer',
        city : 'Stad',
        email : 'E-Post',
        invoiceEmail : 'E-post fakturautskick',
        billingAddress : 'Fakturaadress',
        role : 'Roll',
        management : 'Redigera',
        dateAdded : 'Datum tillagt',
        phoneNumber : 'Telefonnummer',
        accountant : 'Redovisningskonsult',
        name : 'Namn',
        totalBookKept : 'Totalt bokförda transaktioner',
        totalSubscription : 'Totalt prenumerationer',
        mostSold : 'Mest sålda appar',
        totalPartners : 'Totalt antal partners',
        amountDue : 'Belopp',
        imageUploadSuccessful : 'Bilden har laddats upp',
        createNote : 'Skapar anteckning',
        noteCreated : 'Kommentar skapad',
        emptyNote : 'Anteckningsfältet kan inte vara tomt',
        updateNote : 'Uppdaterar anteckningen ...',
        noteUpdated : 'Kommentar uppdaterad',
        pleaseWait : 'Vänta...',
        successCompanyUpdated : 'Företagsinformation uppdaterad',
        requireCompanyNumber : 'Företagsnamn / företagsnummer krävs',
        requireCompanyAddress : 'Adress krävs',
        requirePhonenumber : 'Telefonnummer krävs',
        requireZipCode : 'Postnummer krävs',
        requireCity : 'Stad krävs',
        updateApp : 'Uppdatering av appar lyckades',
        redirectYou : 'Ett ögonblick..',
        confirmInvite : 'Du har bekräftat en inbjudan',
        hi : 'Hej',
        almostDone : 'välkommen att skapa ett konto',
        massReassignAccountant : 'Omfördela konsulter',
        crop : 'Beskär',
        cropping : 'Beskärning',
        finishedUpload : 'Färdig uppladdning',
        uploading : 'Uppladdning',
        processing : 'Bearbetar',
        firstName : 'Förnamn',
        lastName : 'Efternamn',
        other : 'Övrig roll',
        creating : 'Skicka',
        accountantRemove : 'Redovisningskonsulten har tagit bort',
        action : 'Händelse',
        accountNumberRequired : 'Intäktskonto krävs',
        createdCustom : 'Konto inställningar är skapade',
        deletedCustom : 'Konto inställningar är raderade',
        activatedSubscription : 'Aktiv integration',
        deactivatedSubscription : 'Avaktiverad integration',
        createdDeliverySubscription : 'Leveransmappning skapad',
        deletedDeliverySubscription : 'Leveransmappning raderad',
        fileRequired : 'Fil krävs',
        swishFileSuccess : 'Filen är mottagen och inrapporterad',
        invalidFile : 'Ogiltig fil',
        assign : 'Tilldela',
        accountantCompanies : 'Redovisningsbyrå',
        reassignAccountant : 'Omfördela',
        assignTo : 'Tilldela till',
        assigning : 'Tilldela',
        continue : 'Fortsätt',
        prev : 'Föregående',
        next : 'Nästa',
        uploadFinished : 'Filuppladning klar',
        invalidAccountID : 'Ogiltigt ID',
        selectAssignee : 'Välj person',
        selectCompany : 'Välj åtmindstonde ett bolag',
        selectCompanies : 'Välj företag',
        successAssign : 'Lyckad Omfördelning',
        visma : 'Lyckad Visma anslutning',
        invalidSession : 'Något gick fel..',
        subscriptionPage : 'Inställningar',
        switchCard : 'Betalningsmetod uppdaterad till kort',
        redirectWait : 'Ett ögonblick..',
        setUpAccount : 'Aktivera konto',
        emailAddress : 'E-postadress',
        emailUsername : 'E-postadress / Användarnamn',
        password : 'Lösenord',
        confirmPassword : 'Bekräfta lösenord',
        newPassword : 'Nytt lösenord',
        confirmNewPassword : 'Bekräfta nytt lösenord',
        createdPayTermSubscription : 'Betalsätt tillagd',
        deletedPayTermSubscription : 'Betalsätt raderad',
        accountNumber : 'Kontonummer',
        addAccountNumber : 'Lägg till konto',
        adding : 'Skapar..',
        enterNumber : 'Kontonummer',
        invalidNumber : 'Kontonummer får bestå av max 5 siffror',
        accountAddedSuccess : 'Kontonummer tillagd',
        // companies : 'Företag',
        search : 'Sök',
        chooseFile : 'Ladda upp fil',
        addFAQ : 'Lägg till FAQ',
        addFrequent : 'Lägg till en vanlig fråga',
        question : 'Fråga',
        category : 'Kategori',
        addCategory : 'Lägg till Kategori',
        answer : 'Svar',
        dashboardViewOnly : 'Endast översiktsvy',
        add : 'Lägg till',
        awesome : 'Skriv något fantastiskt',
        addNote : 'Skapa anteckning',
        paymentInterval : 'Betalningsintervall',
        monthly : 'Månadsfakturering',
        switchYear : 'Byt till Årsfakturering',
        subscriptionMonth : 'Nya prenumerationer denna månad',
        faqAdded : 'FAQ tillagd',
        switchSuccess : 'Tack, bolaget är nu uppsatt som fakturakund',
        totalAccountant : 'Totalt revisorer',
        connectedCustomer : 'Totalt antal anslutna kunder',
        // totalSubscription : 'Totalt prenumerationer',
        transactionBookkept : 'Totalt bokfört transaktion',
        totalKickback : 'Vunna rabatter',
        subscriptionType : 'Prenumerationstyp',
        saveMoney : 'Totalt besparad arbetstid i pengar',
        totalHours : 'Totalt sparade arbetstimmar',
        appCatalogue : 'Appkatalog',
        overview : 'Översikt',
        categorySelect : 'Välj en kategori',
        chooseCategory : 'Välj kategori',
        genericQuestion : 'Allmänfråga',
        testOrder : 'Skicka ett test verifikat',
        testSend : 'Skicka ett test verifikat',
        notFoundText : 'Å nej! Sidan hittas inte',
        backHome : 'Tillbaka till startsidan',
        optionalSetting : 'Valfria inställningar',
        paymentMappingSetting : 'Betalväxlar mappning',
        accountSetting : 'Konto Mappning',
        bambooHRSetting : 'BambooHR Mappning',
        deliverySetting : 'Leveranssätt Mappning',
        more : 'mer',
        less : 'mindre',
        at : 'på',
        markRead : 'markera som läst',
        testing : 'Testning',
        bambooCreated : 'Inställningar skapades framgångsrikt',
        bambooDeleted : 'Data har tagits bort',
        connectShopify : 'Anslut med Shopify',
        connectStripe : 'Anslut med Stripe',
        buyNow : 'Köp nu',
        price : 'Pris',
        notifyRedirect : 'Länk för mer info',
        fieldValue : 'Värdet får ej vara längre än 50 tecken',
        invalidEmail : 'Ogiltig e-mail',
        invitedSuccessful : 'Inbjudan skickad',
        paid : 'Betald',
        unPaid : 'Obetald',
        processingFile : 'Filen bearbetas för inskanning till ekonomiprogrammet, vänligen vänta',
        fileResponseSuccess : 'Följande verifikat skapades upp',
        fileResponseError : 'Det gick inte att inrapportera verifikaten, se felmeddelande från ekonomiprogrammet',
        viewNotification : 'Visa notifikation',
        createRole : 'Skapa en användarroll',
        selectRole : 'Välj användarroll',
        yourAppConnection : 'Dina kopplingar',
        removeConnection : 'Radera koppling',
        selectApps : 'Välj integration',
        selectSubscription : 'Välj prenumeration',
        selectRelevantApps : 'Välj app',
        initializeConnection : 'Skapa anslutning',
        authenticateApps : 'Autentisera anslutning',
        connect : 'Anslut',
        connected : 'Ansluten',
        selectAndMapFields : 'Mappa fält',
        setRulesForSync : 'Sätt synkregler',
        connectApps : 'Koppla appar',
        mapObjects : 'Objektmappning',
        mapFields : 'Fältmappning',
        setRules : 'Sätt logik regler',
        appConnection : 'Anslutningar',
        appSettings : 'Inställningar',
        connectionObject : 'Objekt',
        mapFilters : 'Mappa filter',
        filters : 'Filter',
        actions : 'Regler',
        intervals : 'Intervaller',
        finished : 'Notifikationer',
        syncUrl : 'Webhooks',
        selectEndpoint : 'Välj Endpoint',
        selectFilters : 'Välj filter',
        selectKeys : 'Välj värde nycklar',
        notifyFinishRule : 'Notifikations regler',
        addValue : 'Lägg till värde',
        selectActionType : 'Välj Regeltyper',
        selectNameType : 'Välj Namntyper',
        selectKeySource : 'Välj nyckel källa',
        selectKeyDestination : 'Välj nyckel destination',
        source : 'Importera från',
        destination : 'Importera till',
        selectOutput : 'Välj resultat',
        selectOutputSource : 'Välj resultat källa',
        selectOutputDestination : 'Välj resultat mål',
        trigger : 'SynktTrigger', 
        triggerStatus : 'Triggerstatus',
        triggerForEvery : 'För varje',
        triggerPeriod : 'period',
        selectStatusType : 'status typ',
        notificationChannel : 'notifikations kanal',
        notificationReceiver : 'mottagare',
        syncWithEndpoint : 'synka med endpoint',
        syncSuccessOrFailure : 'synka vid success och failure',
        seconds : 'sekund',
        mnutes : 'minut',
        hours : 'timma',
        days : 'day',
        complete : 'Färdigställt',
        submit : 'Skicka',
        filterExistence : 'Filter finns redan',
        error : 'Fel',
        info : 'Info',
        testApp : 'Testa synken',
    }
}

const i18n = new VueI18n({
    locale: 'se', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})

export default i18n