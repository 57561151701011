export const en = {
    messages: {
        required: 'This field is required',
        email : 'The {_field_} field must be a valid email',
        min: '{_field_} field must have no less than {length} characters',
        max: 'The {_field_} field should not be greater than {length} characters',
        minmax: 'The {_field_} field must have at least {min} characters and {max} characters at most',
        // minmaxuser: 'The {_field_} field must have at least {min} characters and {max} characters at most',
        // minmaxpass: 'The {_field_} field must have at least {min} characters and {max} characters at most',
        validcom : 'Sample valid {_field_} is xxxxxx-xxxx'
    }
}

export const se = {
    messages: {
        required: 'Fält är obligatoriska',
        email : '{_field_} fältet måste innehålla en giltig e-postadress',
        min: '{_field_} fältet får inte ha mindre än {length} tecken',
        max: '{_field_} fältet får inte ha mer än {length} tecken',
        minmax: '{_field_} fältet får ha minst {min} och max {max} tecken',
        // minmaxuser: '{_field_} fältet får ha minst {min} och max {max} tecken',
        // minmaxpass: '{_field_} fältet får ha minst {min} och {max} tecken',
        validcom : 'Exempel på giltigt format för {_field_} fältet är xxxxxx-xxxx'
    }
}


export default { en, se }