const TYPES = {
    UPDATE_USER_INFO : 'UPDATE_USER_INFO',
    UPDATE_USER_ROLE : 'UPDATE_USER_ROLE',
    UPDATE_AVAILABLE_APPS : 'UPDATE_AVAILABLE_APPS',
    UPDATE_CONNECTION_VALUES : 'UPDATE_CONNECTION_VALUES',
    UPDATE_FAQ_CATEGORY : 'UPDATE_FAQ_CATEGORY',
    UPDATE_FAQ_DATA : 'UPDATE_FAQ_DATA',
    UPDATE_USER_CONNECTION : 'UPDATE_USER_CONNECTION', 
    UPDATE_SINGLE_CONNECTION : 'UPDATE_SINGLE_CONNECTION',
    UPDATE_USER_SUBSCRIPTION : 'UPDATE_USER_SUBSCRIPTION',
    UPDATE_APP_FILTER_LOGS : 'UPDATE_APP_FILTER_LOGS',
    UPDATE_APP_LOGS : 'UPDATE_APP_LOGS',

    
    FILTER_CATEGORY : 'FILTER_CATEGORY',
    SET_FAQ_LIST : 'SET_FAQ_LIST',
    CATEGORY_FAQ_ALL : 'CATEGORY_FAQ_ALL',
    UPDATE_APP_INTEGRATION : 'UPDATE_APP_INTEGRATION',
    SUBSCRIPTION_ALL : 'SUBSCRIPTION_ALL',
    FILTER_WEBAPPS : 'FILTER_WEBAPPS',
    GET_ALL_APPS : 'GET_ALL_APPS',
    SET_USER_COMPANY_ID : 'SET_USER_COMPANY_ID',
    SET_USER_FIRM_ID : 'SET_USER_FIRM_ID',
    SET_USER_COMPANY_BILLING : 'SET_USER_COMPANY_BILLING',
    SET_USER_COMPANY_PAYMENT_TYPE : 'SET_USER_COMPANY_PAYMENT_TYPE',
    UPDATE_ACCOUNTING_TYPE : 'UPDATE_ACCOUNTING_TYPE',
    UPDATE_DASHBOARD_METRICS : 'UPDATE_DASHBOARD_METRICS',
    UPDATE_NOTIFICATION : 'UPDATE_NOTIFICATION',
    UPDATE_SYSTEM_LOGS : 'UPDATE_SYSTEM_LOGS',
    UPDATE_BILLING_HISTORY : 'UPDATE_BILLING_HISTORY',
    
}

export default TYPES