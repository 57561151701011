import { getFirm, getXAmDjango } from './../../../../services/storage-window'

export default {
    UserInfo : {},
    userRole : [],
    availableApps : [],
    copyAvailableApps : [],
    connectionObject : [],
    appConnection : [],
    completeAppSubmission : false,
    userConnections : [],
    singleConnectionObject : {},
    userSubscription : [],
    connectionMade : [],



    userCompanyID : getXAmDjango() || null,
    userFirmID : getFirm() || null,
    appIntegration : [],
    getAccountingType : [],
    copyAppIntegration : [],
    allSubscription : {},
    appFilterState : [],
    faqData : [],
    companyBilling : {},
    setPaymentType : null,
    copyFaqData : [],
    faqCategory : [],
    copyFaqCategory : [],
    dashboardMetrics : {
        total_accounting_savings : 0,
        total_subscriptions : 0,
        total_transactions_book_kept : 0
    },
    notification : {
        all_notifications : [],
        unread_notifications_count : 0,
        all_notifications_count : 0,
    },
    sytemAppLogs : [],
    copySystemLogs : [],
    appType : [],
    AppLogs : [],
    copyAppLog : [],
    billingHistory : [],
    copyBillingHistory : [],
    ownerCompany : null,
    associateCompany : [],
}