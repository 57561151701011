import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import customer from './modules/customer'
import utility from './modules/utility'

Vue.use(Vuex)

export default new Vuex.Store({
    modules : {
        auth,
        customer,
        utility
    }
})
