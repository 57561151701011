
import HTTP from './../../../../services/vue-axios/axios'
import DownloadHTTP from './../../../../services/vue-axios/download'
import endpoints from './../../../../services/endpoint'
import TYPES from './../types'


export default {
    async getUserRole ({ commit }, _) {
        await HTTP.get(endpoints.ROLE_ENDPOINT)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_ROLE, res.data)
                }
            }).catch((_) => {
            })
    },
    async createUserRole ({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.ROLE_ENDPOINT, payload)
            .then(res => {
                console.log(res)
                if (res.status === 201) {
                    dispatch('getUserRole')
                    .then(_ => {
                        resolve(res)
                    })
                    .catch(_ => {
                        resolve(res)
                    })
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getAuthUser ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_INFO, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getFaqList ({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.FAQ_DATA_ENDPOINT)
            .then(res => {
                if (res.status === 200) {
                    // console.log('re:', res)
                    commit(TYPES.UPDATE_FAQ_DATA, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getFaqCategoryList ({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.GET_FAQ_CATEGORY_LIST)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_FAQ_CATEGORY, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getAvailableApps({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.AVAILABLE_APPS)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_AVAILABLE_APPS, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async companyInviteUser ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.USER_COMPANY + `${payload.company_id}/invite/user/`, payload)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async uploadPhoto ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload.formData)
            .then(res => {
                if (res.data.success === true) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getSelectedAppObject ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.AVAILABLE_APPS + `objects/${payload.query}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getAuthLink ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CONNECTION_ENDPOINT + `${payload}/auth/url/`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async bindAppConnectionObject ({ dispatch }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.APP_CONNECT, payload)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async checkConnection ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CHECK_CONNECTION + `?connection=${payload.id}`)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_CONNECTION_VALUES, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async addDefinitionRule ({ commit, dispatch }, payloadData) {
        return new Promise((resolve, reject) => {
            // HTTP.put(endpoints.CONNECTION_ENDPOINT + `${payloadData.id}/add/definitions/`, payloadData.payload)
            HTTP.patch(endpoints.CONNECTION_ENDPOINT + `${payloadData.id}/`, payloadData.payload)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async addTrigger ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.TRIGGER_ENDPOINT, payload)
            .then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async triggerConnection ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(endpoints.TRIGGER_ENDPOINT + `${payload}/runtask/`)
            .then(res => {
                resolve(res)
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getUserConnections ({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CONNECTION_ENDPOINT)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_CONNECTION, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })      
        })
    },
    async getConnectionInformation ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CONNECTION_ENDPOINT + payload.id)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_SINGLE_CONNECTION, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getConnectionAppSetting ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.CONNECTION_ENDPOINT + `${payload}/app_settings`)
            .then(res => {
                if (res.status === 200) {
                    // commit(TYPES.UPDATE_SINGLE_CONNECTION, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteAppConnection ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(endpoints.CONNECTION_ENDPOINT + `${payload}/`)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async updateAppSettings ({ _ }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(endpoints.CONNECTION_ENDPOINT + `${payload.uuid}/app_settings/?name=${payload.app}`, payload.data)
            .then(res => {
                if (res.status === 200) {
                    // commit(TYPES.UPDATE_SINGLE_CONNECTION, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getUserSubscription ({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.SUBSCRIPTION_ENDPOINT)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_USER_SUBSCRIPTION, res.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getAppLogs ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const URL = payload.page ? endpoints.GET_LOGS + payload.page : endpoints.GET_LOGS
            HTTP.get(URL)
            .then(res => {
                if (res.status === 200) {
                    if (payload.page) {
                        commit(TYPES.UPDATE_APP_FILTER_LOGS, res.data.data)
                    } else {
                        commit(TYPES.UPDATE_APP_LOGS, res.data.data)
                    }
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },

















    








    async addAccountNumberForm ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success === true) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    




    async getUserCompany ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getSubscriptionList ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                // console.log('HHH:', res)
                if (res.data.success) {
                    commit(TYPES.SUBSCRIPTION_ALL, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getAppList ({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.GET_APP_LIST)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_APP_INTEGRATION, res.data.results)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getAccountingPlatform ({ commit }, _) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.GET_ACCOUNTING_PLATFORM)
            .then(res => {
                if (res.status === 200) {
                    commit(TYPES.UPDATE_ACCOUNTING_TYPE, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getDashboardMetrics ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                if (res.data.success) {
                    // this.dashboardMetrics = res.data.data
                    commit(TYPES.UPDATE_DASHBOARD_METRICS, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getUserTransaction ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    
    async getUserSwitchPayment ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getUserNotification ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    commit(TYPES.UPDATE_NOTIFICATION, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getSystemAppLogs ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                console.log('system:', res.data.data)
                if (res.data.success) {
                    commit(TYPES.UPDATE_SYSTEM_LOGS, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getUserBillingHistory ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                if (res.data.success) {
                    commit(TYPES.UPDATE_BILLING_HISTORY, res.data.data)
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async switchToInvoice ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async switchToYearly ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getUserInitializePayment ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async updateUserCompany ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.put(payload.URL, payload.data)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    // App config
    async getConfigForm (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async runShopifyAuth (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async runStripeAuth (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getSubscriptionConfig (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async addCustomAccount (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload.data)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteCustomAccount (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(payload.URL)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getVoucherSeries ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getPriceList ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getSubscriptionReadInfo (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async activateSubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deactivateSubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    // async updateAppSettings (_, payload) {
    //     return new Promise((resolve, reject) => {
    //         HTTP.put(payload.URL, payload.dataObject)
    //         .then(res => {
    //             if (res.status === 200) {
    //                 resolve(res)
    //             }
    //         }).catch((err) => {
    //             reject(err)
    //         })
    //     })
    // },
    async getCustomAccounts (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async readNotification ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getAccountType (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getMatchType (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getCountry (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    // async getSubscriptionListApp (_, payload) {
    //     return new Promise((resolve, reject) => {
    //         HTTP.get(payload.URL)
    //         .then(res => {
    //             if (res.status === 200) {
    //                 resolve(res)
    //             }
    //         }).catch((err) => {
    //             reject(err)
    //         })
    //     })
    // },
    async getWaysOfDeliveryOption (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getWaysOfDeliverySubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getTermOfDeliveryOption (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getPaymentTermOption (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getActiveAccountNumbers (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async addAccountNumbers (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getPaymentProvider (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getPaymentTermSubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async createWayDeliverySubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload.dataObject)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async createPaymentTermSubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload.dataObject)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteWaysOfDeliverySubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(payload.URL)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deletePaymentTermSubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(payload.URL)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async performTestSubscription (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async CreateBambooMapping (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload.obj)
            .then(res => {
                if (res.data.success) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async deleteBambooData (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.delete(payload.URL)
            .then(res => {
                if (res.status === 204) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getBambooType (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getBambooFortnoxCause (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getBambooTimeOffs (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getBambooEmpField (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async getBambooMapData (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async uploadSwishFile (_, payload) {
        return new Promise((resolve, reject) => {
            HTTP.post(payload.URL, payload.formData)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async downloadFile ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            DownloadHTTP.get(payload.URL)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    },
    async checkVismaAvailability ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            HTTP.get(endpoints.VISMA_AVAILABILITY + `${payload.sub}/`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            }).catch((err) => {
                reject(err)
            })
        })
    }
}