export default {
    GET_USER (state) {
        return state.UserInfo
    },
    GET_NOTIFICATION (state) {
        return state.notification
    },
    GET_USER_ROLE (state) {
        return state.userRole 
    },
    GET_AVAILABLE_APPS (state) {
        return state.availableApps
    },
    GET_CONNECTION_OBJECT (state) {
        return state.connectionObject
    },
    GET_APP_CONNECTION (state) {
        return state.appConnection
    },
    GET_COMPLETE_SUBMISSION (state) {
        return state.completeAppSubmission
    },
    GET_USER_CONNECTION (state) {
        return state.userConnections
    },
    GET_SINGLE_CONNECTION_OBJECT (state) {
        return state.singleConnectionObject
    },
    GET_USER_SUBSCRIPTION (state) {
        return state.userSubscription
    },
    GET_CONNECTION_MADE (state) {
        return state.connectionMade
    },





    
    GET_SUBSCRIPTION (state) {
        return state.allSubscription
    },
    GET_ACCOUNTING_TYPE (state) {
        return state.getAccountingType
    },
    GET_DASHBOARD_METRICS (state) {
        return state.dashboardMetrics
    },
    GET_OWN_COMPANY (state) {
        return state.ownerCompany
    },
    GET_ASSOCIATE_COM (state) {
        return state.associateCompany
    },
    GET_SYSTEM_LOGS (state) {
        return state.sytemAppLogs
    },
    GET_APP_TYPE (state) {
        return state.appType
    },
    GET_APP_LOG (state) {
        return state.AppLogs
    },
    GET_BILLING_HISTORY (state) {
        return state.billingHistory
    },
    GET_USER_COMPANY_ID (state) {
        return state.userCompanyID
    },
    GET_USER_FIRM_ID (state) {
        return state.userFirmID
    },
    GET_COMPANY_BILLING (state) {
        return state.companyBilling
    },
    GET_PAYMENT_TYPE (state) {
        return state.setPaymentType
    },
    GET_APP_INTEGRATION (state) {
        return state.appIntegration
    },
    GET_APP_FILTER_STATE (state) {
        return state.appFilterState
    },
    GET_FAQ_DATA (state) {
        return state.faqData
    },
    GET_FAQ_CATEGORY (state) {
        return state.faqCategory
    },
}