import moment from 'moment'
import axios from 'axios'
import endpoints from './endpoint'
import helpers from './helper'

export default {
    install (Vue, opts) {
        Vue.prototype.$services = {
            endpoints,
            helpers,
            axios,
            moment
        }
    }
}