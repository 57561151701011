const LOCALE = 'xlocale'
const FIRM = 'xfirm'
const XDJANGO = 'xmdjango'

export const saveLocale = (payload) => {
    window.localStorage.setItem(LOCALE, payload)
}
export const getLocale = () => {
    return window.localStorage.getItem(LOCALE)
}

export const saveFirm = data => {
    // console.log('wht is data:', JSON.stringify(data))
    window.sessionStorage.setItem(FIRM, JSON.stringify(data))
}

export const getFirm = () => {
    return window.sessionStorage.getItem(FIRM)
}

export const saveXAmDjango = data => {
    window.sessionStorage.setItem(XDJANGO, data)
}

export const getXAmDjango = () => {
    return window.sessionStorage.getItem(XDJANGO)
}

export const deleteUserData = () => {
    window.sessionStorage.removeItem(FIRM)
    window.sessionStorage.removeItem(XDJANGO)
}

export const setUserCompany = (data, entity) => {
    if (entity === 'Firm') {
        saveFirm(data)
        console.log('firm is saved')
    } else {
        saveXAmDjango(data)
    }
}

export default { saveLocale, getLocale, setUserCompany, deleteUserData }